import React from 'react'
import Box from '@mui/material/Box'
import Text from '../../components/Text'
import { ParticipantBreakdownStateless, TGenericExpense } from '../Dashboard/DateRequests/components/BuckBreaker/CostBreakdown'
import { TBuckBreaker, User } from '../../utils/types'
import { ImagePreviewModal } from '../Dashboard/DateRequests/components/DateMemories/DateMedia'
import { useUiStore } from '../../stores/uiStore'
import { pToU, toCurrency } from '../../utils/helpers'
import BBConfirmPayment from './BBConfirmPayment'

type IBBCostBreakdown = {
  bbData: TBuckBreaker
}


export const BBCostBreakdown = ({bbData}: IBBCostBreakdown) => {
  const { showDialog } = useUiStore()
  const [panelSelected, setPanelSelected] = React.useState<number>()
  const openPreview = (receipt?: string) => () => {
    if (!receipt) return

    showDialog({
      hideModalWrapper: true,
      dialogBody: <ImagePreviewModal image={receipt} />
    })
  }
  const openPaymentConfirmationModal = (pid: string) => () => {
    showDialog({
      dialogBody: <BBConfirmPayment bbData={bbData} pid={pid} />
    })
  }

  const onPanelSelected = (index: number) => () => {
    if (panelSelected === index) {
      setPanelSelected(undefined)
    } else {
      setPanelSelected(index)
    }
  }

  type TArgs = (pid: string) => TGenericExpense[]

  const expensesForYou: TArgs = (pid: string) => 
    bbData.data.expenses
      .filter(e => e.debtors.includes(String(pid)))
      .map(e => ({
        receipt: e.receipt || '',
        description: e.description,
        amount: e.amount,
        debtors: e.debtors,
        createdBy: e.created_by
      }))

  const getParticipant = (pid: string) => bbData.buck_breaker_participants.find(p => +p.id === +pid)

  const payments = (pid: string) => expensesForYou(pid).reduce((acc, e) => {
    if (e.createdBy) {
      return {
        ...acc,
        [e.createdBy]: (acc[e.createdBy] || 0) + (e.amount / e.debtors.length)
      }
    } 
    return acc
  }, {} as Record<number|string, number>)
  
  const paymentPrompt = (pid: string) => Object.entries(payments(pid)).map(([pid, amount]) => {
    const name = getParticipant(String(pid))?.name.split(" ")?.[0]
    return `Pay ${name} ${toCurrency(amount)}`
  })

  const participantUser = (pid: string) => {
    const p = getParticipant(pid)
    return !!p ? pToU(p) : ({} as User)
  }

  return (
    <Box mt={2}>
      <Text weight="semibold" mb={1}>Breakdown</Text>
      {Object.entries(bbData.data.expense_payments).map(([pid, ep], i) => {
        return (
          <ParticipantBreakdownStateless 
            openPreview={openPreview(ep.payment_proof)}
            openPaymentConfirmationModal={openPaymentConfirmationModal(pid)}
            paymentPrompt={paymentPrompt(pid)}
            panelOpen={i === panelSelected}
            onPanelSelected={onPanelSelected(i)}
            participantUser={participantUser(pid)}
            amountOwed={ep.amount_owing || 0}
            expensesForYou={expensesForYou((pid))}
            paid={ep.status === "paid"}
            pending={ep.status === 'pending'}
            expensePayment={{receipt: ep.payment_proof || ''}}
            paymentStatus={ep.status}
            key={pid}
            isOwnedByCurrentUser
          />
        )
      })}
    </Box>
  )
}