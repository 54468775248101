import { useState } from "react";
import { useFetchDateExpenseQuery, useSaveDateExpenseMutation } from "../../../../../services/api";
import { useAuthStore } from "../../../../../stores/authStore";
import { getPIDOfUser } from "../../../../../utils/helpers";
import { DateRequest } from "../../../../../utils/types";

export const useBuckBreaker = (dateInfo: DateRequest) => {
  const { 
    data: expensesResponse, 
    isLoading: fetchingDateExpenses 
  } = useFetchDateExpenseQuery({ request_id: dateInfo.request_id })
  const [saveExpenseRequest, { isLoading: isSavingExpense }] = useSaveDateExpenseMutation()
  const [participantPanelOpen, setParticipantPanelOpen] = useState<null | number>(null)
  const { currentUser } = useAuthStore()
  const onPanelSelected = (panelId: number) => {
    setParticipantPanelOpen(panelId === participantPanelOpen ? null : panelId)
  }
  const {expenses=[], expense_payments=[]} = expensesResponse?.payload || {}

  return {
    saveExpenseRequest,
    userPID: getPIDOfUser(currentUser, dateInfo.date_participants),
    isSavingExpense,
    expenses: expenses,
    expensePayments: expense_payments,
    fetchingDateExpenses,
    hasExpenses: !!expenses.length,
    onPanelSelected,
    participantPanelOpen
  }
}
