import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Text from '../../../../../components/Text'
import { DateRequest, User } from '../../../../../utils/types'
import { DateExpense, DateExpensePayment } from '../../../../../services/api'
import { getPIDOfUser, getUserByPID, toCurrency } from '../../../../../utils/helpers'
import { UserInfo } from '../../../../../components/UserInfo/UserInfo'
import { colors } from '../../../../../styles/_var'
import Button from '../../../../../components/Button/Button';
import { useAuthStore } from '../../../../../stores/authStore';
import { useUiStore } from '../../../../../stores/uiStore';
import ConfirmPayment from './ConfirmPayment';
import { ImagePreviewModal } from '../DateMemories/DateMedia';

type CommonProps = {
  expenses: DateExpense[]
  dateInfo: DateRequest
}

type CostBreakdownProps = {
  expensePayments: DateExpensePayment[],
  panelOpen?: number | null
  onPanelSelected: (panelId: number) => void
} & CommonProps

type ParticipantBreakdownProps = {
  expensePayment: DateExpensePayment
  participantUser?: User | null
  panelOpen: boolean
  onPanelSelected: () => void
  isOwnedByCurrentUser: boolean
} & CommonProps

export const ParticipantBreakdown = ({ 
  expensePayment,
  participantUser,
  panelOpen,
  onPanelSelected,
  expenses,
  isOwnedByCurrentUser,
  dateInfo
}: ParticipantBreakdownProps) => {
  const { showDialog } = useUiStore()
  if (!participantUser) return null

  const pending = expensePayment.payment_status === 'pending'
  const paid = expensePayment.payment_status === 'paid'

  const expensesForYou = expenses
    .filter(e => e.debtors.includes(expensePayment.date_participant_id) 
      && (e.date_participant_id !== getPIDOfUser(participantUser, dateInfo.date_participants)))

  const genericExpenses: TGenericExpense[] = expensesForYou.map(e => ({
    receipt: e.receipt || '',
    description: e.description,
    amount: e.amount,
    debtors: e.debtors
  }))

  const paymentPrompt = expensesForYou.map(e => {
    const name = getUserByPID(e.date_participant_id, dateInfo.date_participants)?.full_name.split(" ")?.[0]
    const amountOwed = e.debtors.length === 0 ? 0 : e.amount/e.debtors.length
    return `Pay ${name} ${toCurrency(amountOwed)}`
  })

  const openPaymentConfirmationModal = () => {
    showDialog({
      dialogBody: <ConfirmPayment id={expensePayment.id} request_id={dateInfo.request_id} />
    })
  }

  const openPreview = () => {
    showDialog({
      hideModalWrapper: true,
      dialogBody: <ImagePreviewModal image={expensePayment.receipt || ''} />
    })
  }

  return (
    <ParticipantBreakdownStateless 
      openPreview={openPreview}
      openPaymentConfirmationModal={openPaymentConfirmationModal}
      paymentPrompt={paymentPrompt}
      panelOpen={panelOpen}
      onPanelSelected={onPanelSelected}
      participantUser={participantUser}
      amountOwed={expensePayment.amount_owed || 0}
      expensesForYou={genericExpenses}
      paid={paid}
      pending={pending}
      expensePayment={{receipt: expensePayment.receipt || ''}}
      paymentStatus={expensePayment.payment_status}
      isOwnedByCurrentUser={isOwnedByCurrentUser}
    />
  )
}

export type TGenericExpense = {
  receipt: string
  description: string
  amount: number
  debtors: Array<string | number>
  createdBy?: string
}

type IParticipantBreakdownStateless = {
  onPanelSelected: () => void
  participantUser: User
  amountOwed: number
  paymentStatus: 'pending' | 'paid'
  panelOpen: boolean
  paymentPrompt: string[]
  openPreview: () => void
  paid: boolean
  pending: boolean
  expensesForYou: Array<TGenericExpense>
  openPaymentConfirmationModal: () => void
  expensePayment: { receipt: string }
  isOwnedByCurrentUser: boolean
}

export const ParticipantBreakdownStateless = ({
  onPanelSelected,
  participantUser,
  amountOwed,
  paymentStatus,
  panelOpen,
  paymentPrompt,
  openPreview,
  paid,
  pending,
  expensesForYou,
  openPaymentConfirmationModal,
  expensePayment,
  isOwnedByCurrentUser
}: IParticipantBreakdownStateless) => {
  return (
    <>
      <MenuItem sx={{padding: 0}} onClick={onPanelSelected}>
        <Box py={1} width="100%">
          <Box display="flex" justifyContent="space-between">
            <UserInfo 
              user={participantUser} 
              onlyShowName 
              useSmallAvatar 
              titleTextVariant='regular'  
            />
            {(amountOwed !== undefined) && (
              <Text 
                weight="semibold"
                variant='medium' 
                color={paymentStatus === 'pending' ? colors.danger : colors.green}
              >
                {toCurrency(amountOwed)}
              </Text>
            )}
          </Box>
        </Box>
      </MenuItem>
      {amountOwed > 0 && (
        <Collapse in={panelOpen}>
          <Box ml={5}>
            {panelOpen && <Divider />}
            {expensesForYou.map((e, i) => (
              <Box 
                mt={1} 
                mb={i === (expensesForYou.length - 1) ? 1 : undefined} 
                key={i} 
                display="flex" 
                justifyContent="space-between"
              >
                <Text>{e.description}</Text>
                <Text>{toCurrency(e.amount/e.debtors.length)}</Text>
              </Box>
            ))}
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {(isOwnedByCurrentUser && pending) ? (
                <Button 
                  variant='contained' 
                  style={{paddingTop: 1, paddingBottom: 1, background: colors.secondary}}
                  onClick={openPaymentConfirmationModal}
                >
                  <Text useDiv variant='small' color={colors.white} weight='bold'>
                    I've paid
                  </Text>
                </Button>
                ) : (paid && !!expensePayment.receipt) ? null : <div />
              }
              {(paid && !!expensePayment.receipt) && (
                <Text 
                  link 
                  variant="small" 
                  color={colors.link} 
                  weight="semibold" 
                  underline
                  onClick={openPreview}
                >
                  view receipt
                </Text>
              )}
              <Box py={1}>
                {paymentPrompt.map((p, i) => (
                  <Text variant="small" weight='semibold' right key={i}>
                    {p}
                  </Text>
                ))}
              </Box>
            </Box>
          </Box>
        </Collapse>
      )}
      <div />
      <Divider sx={{marginTop: 0, marginBottom: 0}} />
    </>
  )
}


const CostBreakdown = ({ 
  dateInfo, 
  expensePayments,
  panelOpen,
  onPanelSelected,
  ...rest
}: CostBreakdownProps) => {
  const { currentUser } = useAuthStore()
  
  return (
    <Box mt={2}>
      <Text weight='semibold' mb={1}>Breakdown</Text>
      {expensePayments.map((e, i) => (
        <ParticipantBreakdown 
          {...rest}
          dateInfo={dateInfo}
          isOwnedByCurrentUser={currentUser.username === getUserByPID(e.date_participant_id, dateInfo.date_participants)?.username}
          onPanelSelected={() => onPanelSelected(i)}
          panelOpen={panelOpen === i}
          key={e.id} 
          expensePayment={e} 
          participantUser={getUserByPID(e.date_participant_id, dateInfo.date_participants)}
        />
      ))}
    </Box>
  )
}

export default CostBreakdown