import React from 'react'
import Box from '@mui/material/Box'
import { TBBParticipant } from '../../utils/types'
import Avatar from '../../components/Avatar/Avatar'
import Text from '../../components/Text'

type IParticipantAvatars = {
  data: TBBParticipant[],
  onRemove: (pid: string) => void,
  participantsBeingDeleted: string[]
}

const ParticipantAvatars = ({data, onRemove, participantsBeingDeleted}: IParticipantAvatars) => {
  return (
    <Box display="flex" justifyContent="center">
      {data.map((participant, i) => (
        <Avatar 
          key={i} 
          style={{filter: participantsBeingDeleted.includes(participant.id) ? 'opacity(0.3)' : 'opacity(1)'}}
          alt={participant.name} 
          src={participant.avatar}
          onClick={() => onRemove(participant.id)}
          addBorder
          mr={1}
        >
          <Text variant="medium" weight="semibold">
            {participant.name?.substring(0, 1).toUpperCase()}
          </Text>
        </Avatar>
      ))}
    </Box>
  )
}

export default ParticipantAvatars