import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CalendarIcon from '@mui/icons-material/DateRangeRounded';
import RightArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import OpenRequestIcon from '@mui/icons-material/Diversity2Outlined';
import MoneyIcon from '@mui/icons-material/LocalAtmRounded';
import Text from '../../../components/Text'
import { DashboardCard } from './Home.styles'
import { colors, spacing } from '../../../styles/_var'
import TextWithIcon from '../../../components/TextWithIcon';
import { Link } from 'react-router-dom';
import { routes } from '../../../utils/constants';
import PeopleIcon from '@mui/icons-material/GroupAddOutlined';
import OpenRequestDrawer from './OpenRequestDrawer';
import BuckBreakerDrawer from './BuckbBreakerDrawer';

const DashboardActions = React.memo(() => {
  const [openDateRequestDrawer, setOpenDateRequestDrawer] = React.useState(false)
  const [openBuckBreakerDrawer, setOpenBuckBreakerDrawer] = React.useState(false)
  const toggleOpenRequestDrawer = () => setOpenDateRequestDrawer(!openDateRequestDrawer)
  const toggleBuckBreakerDrawer = () => setOpenBuckBreakerDrawer(!openBuckBreakerDrawer)

  return (
    <>
      <Box mt={3}>
        <Text mt={3} mb={2}>What do you want to do today?</Text>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DashboardCard elevation={2}>
              <Link to={routes.PLAN_ACTIVITY}>
                <Box padding={spacing.xxs} bgcolor={colors.pink} className="jr-group-activity">
                  <PeopleIcon 
                    fontSize='large' 
                    sx={{display: 'block', marginBottom: spacing.xxs}} 
                  />
                  <TextWithIcon 
                    weight="semibold" 
                    after 
                    justifyContent='space-between' 
                    icon={<RightArrowIcon />}
                  >
                    Group Activity
                  </TextWithIcon>
                </Box>
              </Link>
            </DashboardCard>
          </Grid>
          <Grid item xs={6}>
            <DashboardCard elevation={2}>
              <Link to={routes.ALL_FRIENDS}>
                <Box padding={spacing.xxs} bgcolor={colors.lightGreen} className='jr-plan-date'>
                  <CalendarIcon fontSize='large' sx={{display: 'block', marginBottom: spacing.xxs}} />
                  <TextWithIcon 
                    weight="semibold" 
                    after 
                    justifyContent='space-between' 
                    icon={<RightArrowIcon />}
                  >
                    Plan a date
                  </TextWithIcon>
                </Box>
              </Link>
            </DashboardCard>
          </Grid>
          <Grid item xs={6}>
            <DashboardCard elevation={2}>
              <Box padding={spacing.xxs} bgcolor={colors.lightyellow} onClick={toggleOpenRequestDrawer} className='jr-open-invite'>
                <OpenRequestIcon fontSize='large' sx={{display: 'block', marginBottom: spacing.xxs}} />
                <TextWithIcon 
                  weight="semibold" 
                  after 
                  justifyContent='space-between' 
                  icon={<RightArrowIcon />}
                >
                  Open Invite
                </TextWithIcon>
              </Box>
            </DashboardCard>
          </Grid>
          <Grid item xs={6}>
            <DashboardCard elevation={2}>
              <Box padding={spacing.xxs} bgcolor={colors.lightergrey} onClick={toggleBuckBreakerDrawer} className='jr-open-invite'>
                <MoneyIcon fontSize='large' sx={{display: 'block', marginBottom: spacing.xxs}} />
                <TextWithIcon 
                  weight="semibold" 
                  after 
                  justifyContent='space-between' 
                  icon={<RightArrowIcon />}
                >
                  Buck Breaker
                </TextWithIcon>
              </Box>
            </DashboardCard>
          </Grid>
        </Grid>
      </Box>
      <OpenRequestDrawer isOpen={openDateRequestDrawer} closeDrawer={toggleOpenRequestDrawer} />
      <BuckBreakerDrawer isOpen={openBuckBreakerDrawer} closeDrawer={toggleBuckBreakerDrawer} />
    </>
  )
})

export default DashboardActions
