import React from 'react'
import Box from '@mui/material/Box'
import Button from '../../components/Button/Button'
import Input from '../../components/Input';
import { colors } from '../../styles/_var'
import PhoneNoInput from '../../components/PhoneNoInput';
import Drawer from '../../components/Drawer/Drawer';
import Text from '../../components/Text';
import RadioButton from '../../components/RadioButton/RadioButton';
import { isEmail } from '../../utils/helpers';

import { TFormPayload } from "./BuckBreakerBreakdown"

type IAddParticipantFormDrawer = {
  isOpen: boolean
  closeDrawer: () => void
  onSubmit: (values: TFormPayload) => void
  isLoading: boolean
}

const BBAddParticipantDrawer = ({isOpen, closeDrawer, onSubmit, isLoading}: IAddParticipantFormDrawer) => {
  const [contactType, setContactType] = React.useState<undefined | "email" | "phone">(undefined)
  const [payload, setPayload] = React.useState<TFormPayload>({name: '', contact: ''})
  const onChange = (type: keyof TFormPayload) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPayload(prev => ({...prev, [type]: e.target.value}))
  }
  const onSubmitForm = () => {
    onSubmit(payload)
  }

  return (
    <Drawer
      disableEnforceFocus
      showCloseIcon
      anchor='bottom'
      open={isOpen}
      onOpen={() => {}}
      onClose={closeDrawer}
      header='Add Participant'
    >
      <Box p={2}>
        <Input placeholder='Enter participant name' size="small" mb={2} onChange={onChange("name")} />
        {!!payload.name.trim() && (
          <Box>
            <Box>
              <Text variant="regular" weight="semibold">Add Contact by</Text>
              <RadioButton 
                options={["Email", "Phone"]} 
                onChange={e => {setContactType(e.toLowerCase())}} />
            </Box>
            {contactType === "email" && (
              <Input 
                placeholder='Enter email' 
                onChange={onChange("contact")} 
                size="small" 
                error={!!payload.contact && !isEmail(payload.contact)}
                mb={2} 
              />
            )}
            {contactType === "phone" && (
              <Box mb={2}>
                <PhoneNoInput onChange={onChange("contact")} />
              </Box>
            )}
          </Box>
        )}
        <Button 
          variant="contained" 
          disabled={!payload.name.trim() || !payload.contact.trim()}
          onClick={onSubmitForm}
          loading={isLoading}
          center 
        >
          <Text variant="regular" weight="semibold" color={colors.white}>
            Add New Participant
          </Text>
        </Button>
      </Box>
    </Drawer>
  )
}

export default BBAddParticipantDrawer