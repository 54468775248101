import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Text from '../../../../../components/Text'
import Divider from '@mui/material/Divider'
import UploadIcon from '@mui/icons-material/CloudUpload';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '../../../../../components/Button/Button'
import { colors } from '../../../../../styles/_var'
import { useUiStore } from '../../../../../stores/uiStore'
import Input from '../../../../../components/Input'
import { FormInputWrapper } from '../../../../../components/FormInput/FormInput.styles'
import { DateRequest } from '../../../../../utils/types'
import CheckBox from '@mui/material/Checkbox'
import { UserInfo } from '../../../../../components/UserInfo/UserInfo'
import { generateRandomNumber, participantToUser, uploadToS3 } from '../../../../../utils/helpers'
import { useAuthStore } from '../../../../../stores/authStore'
import InputAdornment from '@mui/material/InputAdornment'
import ImageUpload from '../../../../../components/ImageUpload'
import TextWithIcon from '../../../../../components/TextWithIcon'
import { AWS_BASE_URL, RECEIPTS_FOLDER } from '../../../../../utils/constants';
import { DateExpense, Participant } from '../../../../../services/api';
import { getFilenameFromS3Url } from '../../../../../utils/helpers';
import Select from '../../../../../components/Select';
import MenuItem from '@mui/material/MenuItem';

export type SaveExpensePayload = Omit<DateExpense, 'date_participant_id' | 'id' | 'amount'> & {id?: number, amount: '' | number}

export type onSaveExpenseArgs = Omit<SaveExpensePayload, 'amount'> & {amount: number}

type AddExpenseModalProps = {
  dateInfo: DateRequest,
  onSave: (payload: onSaveExpenseArgs) => void,
  editPayload?: SaveExpensePayload
}

const useAddExpenseModal = (
  dateInfo: DateRequest, 
  initialFormData?: SaveExpensePayload,
) => {
  const { cancelDialog } = useUiStore()
  const { currentUser } = useAuthStore()
  const currentUserParticipantId = dateInfo?.date_participants?.find(p => p.user?.username === currentUser.username)?.id
  const defaultFormData: SaveExpensePayload = {
    id: undefined,
    amount: '',
    receipt: '',
    payment_info: '',
    debtors: !!currentUserParticipantId ? [currentUserParticipantId] : [],
    description: ''
  }
  const [formData, setFormData] = useState<SaveExpensePayload>(initialFormData || defaultFormData)
  const [uploadingReceipt, setUploadingReceipt] = useState(false)
  const [formError,] = useState({
    participants: '',
    amount: '',
    description: ''
  })
  const receiptName = `${RECEIPTS_FOLDER}/${generateRandomNumber(10)}-${currentUserParticipantId}.jpg`
  const receiptUrl = `${AWS_BASE_URL}/${receiptName}`
  const { id, amount, receipt, payment_info, description, debtors } = formData

  const clearParticipants = () => {
    setFormData({...formData, debtors: []})
  }

  const selectAllParticipants = () => {
    setFormData({...formData, debtors: dateInfo.date_participants?.map(p => p.id) || []})
  }

  const addToParticipants = (id: number | string) => {
    setFormData({...formData, debtors: [...debtors, +id]})
  }

  const removeParticipant = (id: number | string) => {
    setFormData({...formData, debtors: debtors.filter(pid => pid !== id)})
  }

  const toggleParticipant = (id: number | string) => {
    if (debtors.includes(+id)) {
      removeParticipant(+id)
    } else {
      addToParticipants(+id)
    }
  }

  const uploadReceipt = async (image: string) => {
    setUploadingReceipt(true)
    try {
      await uploadToS3({ fileUrl: image, filename: receiptName})
    } finally {
      setUploadingReceipt(false)
      setFormData({...formData, receipt: receiptUrl})
    }
  }

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({...formData, amount: +e.target.value})
  }

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({...formData, description: e.target.value})
  }

  const onPaymentInfoChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({...formData, payment_info: e.target.value})
  }

  const allSelected = debtors.length === dateInfo.date_participants?.length

  const canSave = !!amount && !!description && !!debtors.length

  const title = id ? `Editing Expense #${id}` : 'Add Expense'

  return {
    id,
    title,
    debtors,
    clearParticipants,
    selectAllParticipants,
    toggleParticipant,
    amount,
    onAmountChange,
    allSelected,
    closeModal: cancelDialog,
    canSave,
    formError,
    onDescriptionChange,
    description,
    paymentInfo: payment_info,
    onPaymentInfoChange,
    uploadingReceipt,
    receipt,
    uploadReceipt
  }
}

const AddExpenseModal = ({ 
  dateInfo,
  onSave,
  editPayload
}: AddExpenseModalProps) => {
  const {
    id,
    title,
    debtors,
    clearParticipants,
    selectAllParticipants,
    toggleParticipant,
    amount,
    onAmountChange,
    allSelected,
    closeModal,
    canSave,
    onDescriptionChange,
    description,
    formError,
    paymentInfo='',
    onPaymentInfoChange,
    uploadingReceipt,
    receipt='',
    uploadReceipt
  } = useAddExpenseModal(dateInfo, editPayload)
  const saveNow = () => {
    onSave({
      id,
      amount: Number(amount) * 100, 
      description, 
      debtors,
      receipt,
      payment_info: paymentInfo
    })
  }

  return (
    <AddExpenseModalStateless 
      title={title}
      formError={formError}
      description={description}
      amount={amount}
      receipt={receipt}
      uploadingReceipt={uploadingReceipt}
      paymentInfo={paymentInfo}
      debtors={debtors}
      allSelected={allSelected}
      canSave={canSave}
      participants={dateInfo.date_participants || []}
      selectAllParticipants={selectAllParticipants}
      onPaymentInfoChange={onPaymentInfoChange}
      onDescriptionChange={onDescriptionChange}
      onAmountChange={onAmountChange}
      onCancel={closeModal}
      uploadReceipt={uploadReceipt}
      toggleParticipant={toggleParticipant}
      clearParticipants={clearParticipants}
      onSave={saveNow}
    />
  )
}

export default AddExpenseModal

type IAddExpenseModalStatelessProps = {
  title?: string
  formError: any
  description: string
  amount: number | ''
  receipt: string
  debtors: Array<string | number>
  uploadingReceipt: boolean
  paymentInfo: string
  allSelected: boolean
  canSave: boolean
  participants: Participant[]
  clearParticipants: () => void
  selectAllParticipants: () => void
  onDescriptionChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onAmountChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onPaidByChange?: (pid: string) => void
  onCancel: () => void
  uploadReceipt: (image: string) => Promise<void>
  toggleParticipant: (id: number | string) => void
  onPaymentInfoChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onSave: () => void
}

export const AddExpenseModalStateless = ({
  title,
  formError,
  description,
  amount,
  receipt,
  uploadingReceipt,
  paymentInfo,
  debtors,
  allSelected,
  canSave,
  participants,
  selectAllParticipants,
  onPaymentInfoChange,
  onDescriptionChange,
  onAmountChange,
  onPaidByChange,
  onCancel,
  uploadReceipt,
  toggleParticipant,
  clearParticipants,
  onSave,
}: IAddExpenseModalStatelessProps) => {
  return (
    <Box minWidth={320}>
      {!!title && (
        <>
          <Box px={2} py={1.5}>
            <Text weight="semibold" variant="medium">
              {title}
            </Text>
          </Box>
          <Divider />
        </>
      )}
      <Box p={2}>
        <FormInputWrapper>
          <Text variant="regular" weight="semibold">Description*</Text>
          <Input 
            error={!!formError.description}
            helperText={formError.description}
            placeholder='i.e drinks and fries expense' 
            size='small' 
            mt={0.5} 
            value={description}
            onChange={onDescriptionChange}
          />
        </FormInputWrapper>
        <FormInputWrapper>
          <Text variant="regular" weight="semibold">Amount*</Text>
          <Input 
            error={Number.isNaN(+amount)}
            helperText={formError.amount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Text lighten>$</Text>
                </InputAdornment>
              )
            }}
            value={amount}
            onChange={onAmountChange}
            placeholder='i.e 3000' 
            size='small' 
            mt={0.5} 
          />
        </FormInputWrapper>
        <FormInputWrapper>
          <Text variant="regular" weight="semibold">
            Receipt
          </Text>
          <ImageUpload
            noPreview
            onImageChange={uploadReceipt}
            maxWidth={300}
            contentArea={({ openFileChooser }) => (
              <Box 
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  border: `solid 1px ${colors.borderColor}`,
                  borderRadius: 5,
                  padding: 8
                }}
              >
                <TextWithIcon 
                  lighten 
                  icon={<UploadIcon color="info" />} 
                  onClick={openFileChooser}
                >
                  {receipt ? getFilenameFromS3Url(receipt) : 'Upload Receipt'}
                </TextWithIcon>
                {(!uploadingReceipt && !!receipt) && <CheckIcon color="success" />}
                {uploadingReceipt && <CircularProgress color="info" size={15} />}
              </Box>
            )}
          />
        </FormInputWrapper>
        {!!onPaidByChange && (
          <FormInputWrapper>
            <Text variant="regular" weight="semibold">
              Who paid for this?
            </Text>
            <Select placeholder='Who paid for this?' fullWidth onChange={e => onPaidByChange(String(e.target.value) as string)}>
              {participants?.map(p => (
                <MenuItem key={p.id} value={p.id}>
                  <Text variant="regular">{p.name || p.contact}</Text>
                </MenuItem>
              ))}
            </Select>
          </FormInputWrapper>
        )}
        <FormInputWrapper>
          <Text variant="regular" weight="semibold">
            Payment Details
          </Text>
          <Input
            multiline
            rows={2}
            value={paymentInfo || ''}
            onChange={onPaymentInfoChange}
            placeholder='i.e Make payment to my interac johndoe@gmail.com' 
            size='small' 
            mt={0.5} 
          />
        </FormInputWrapper>
        <Box 
          display="flex" 
          justifyContent="space-between" 
          alignItems="center"
        >
          <Text 
            variant="regular" 
            weight="semibold" 
            color={!!formError.participants ? colors.danger : undefined}
          >
            Participants Involved
          </Text>
          <Text
            onClick={allSelected ? clearParticipants : selectAllParticipants}
            color={colors.link}
            variant="small" 
            link 
          >
            {allSelected ? 'clear' : 'select all'}
          </Text>
        </Box>
        <Box maxHeight={400} overflow="scroll">
          {participants?.map(p => (
            <Box key={p.id} display="flex" alignItems="center" mt={1.5}>
              <CheckBox 
                checked={debtors.includes(p.id)}
                sx={{marginRight: 1}} 
                size="medium"
                onClick={() => toggleParticipant(p.id)} 
              />
              <UserInfo
                useSmallAvatar
                titleTextVariant="regular"
                user={participantToUser(p)} 
                onlyShowName 
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button 
          mr={2} 
          variant="outlined" 
          onClick={onCancel}
        >
          <Text useDiv weight="semibold" variant="small">Cancel</Text>
        </Button>
        <Button 
          variant="contained" 
          disabled={!canSave}
          onClick={onSave}
        >
          <Text useDiv weight="semibold" variant="small" color={colors.white}>
            Save
          </Text>
        </Button>
      </Box>
    </Box>
  )
}

