import React from 'react'
import Drawer from '../../components/Drawer/Drawer';
import { AddExpenseModalStateless } from '../Dashboard/DateRequests/components/BuckBreaker/AddExpenseModal';
import { TFormPayloadExpense } from './useBuckBreaker';
import { Participant } from '../../services/api';

type IBBAddExpenseModalProps = {
  isOpen: boolean,
  isLoading: boolean,
  canSave: boolean,
  allSelected: boolean,
  closeDrawer: () => void,
  onSubmit: (values: any) => void
  formData: TFormPayloadExpense
  onChange: (type: keyof TFormPayloadExpense) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => void
  toggleDebtors: (id: string | number) => void
  clearDebtors: () => void
  selectAllDebtors: () => void
  formError: Record<string, string>
  uploadReceipt: (image: string) => Promise<void>
  uploadingReceipt: boolean
  onSave: () => void
  participants: Participant[]
}

const BBAddExpenseDrawer = ({
  isOpen,
  closeDrawer,
  formData,
  onChange,
  toggleDebtors,
  clearDebtors,
  selectAllDebtors,
  allSelected,
  canSave,
  formError,
  uploadReceipt,
  uploadingReceipt,
  onSave,
  participants
}: IBBAddExpenseModalProps) => {
  return (
    <Drawer
    disableEnforceFocus
    showCloseIcon
    anchor='bottom'
    open={isOpen}
    onOpen={() => {}}
    onClose={closeDrawer}
    header='Add Expense'
  >
    <AddExpenseModalStateless 
      description={formData.description || ''}
      amount={formData.amount}
      receipt={formData.receipt}
      paymentInfo={formData.paymentInfo}
      onAmountChange={onChange('amount')}
      onDescriptionChange={onChange('description')}
      onPaymentInfoChange={onChange('paymentInfo')}
      toggleParticipant={toggleDebtors}
      clearParticipants={clearDebtors}
      selectAllParticipants={selectAllDebtors}
      allSelected={allSelected}
      canSave={canSave}
      formError={formError}
      debtors={formData.debtors}
      uploadReceipt={uploadReceipt}
      uploadingReceipt={uploadingReceipt}
      onCancel={closeDrawer}
      onSave={onSave}
      participants={participants}
      onPaidByChange={onChange('created_by')}
    />
  </Drawer>
  )
}

export default BBAddExpenseDrawer
