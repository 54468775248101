import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { TBBParticipant, TBuckBreakerData, TBuckBreakerExpense, User } from '../../utils/types'
import { ExpenseItemStateLess } from '../Dashboard/DateRequests/components/BuckBreaker/ExpenseItem'
import Text from '../../components/Text'
import { colors } from '../../styles/_var'
import { pToU, retrieveErrorMessage } from '../../utils/helpers'
import { useUiStore } from '../../stores/uiStore'
import { ExpenseInfoStateless } from '../Dashboard/DateRequests/components/BuckBreaker/ExpenseInfo'
import { useDeleteBBExpenseMutation } from '../../services/api'

type ExpensesListProps = {
  data: TBuckBreakerData
  allParticipants: TBBParticipant[]
} & BoxProps

const BBExpensesList = ({ data, allParticipants, ...rest }: ExpensesListProps) => {
  const { showDialog } = useUiStore()
  const expenses = data.expenses
  const getDebtors = (ids: string[]) => allParticipants.filter(p => ids.includes(`${p.id}`)).map(pToU)
  const showMoreInfo = (e: TBuckBreakerExpense) => () => {
    showDialog({
      dialogBody:(
        <ExpenseInfo 
          data={data} 
          allParticipants={allParticipants} 
          expense={e} 
        />
      )
    })
  }
  const paidByFullName = (id: string) => allParticipants.find(p => +p.id === +id)?.name || ''

  return (
    <Box {...rest}>
      <Text weight="semibold" mb={1}>All Expenses</Text>
      {!expenses.length && (
        <Text center variant="regular" weight="semibold" color={colors.danger}>
          No Expenses added yet.
        </Text>
      )}
      {expenses?.map(e => {
        const amountOwned = e.debtors.includes(e.created_by) 
          ?  e.amount - (e.amount/e.debtors.length)
          : e.amount
        return (
          <ExpenseItemStateLess 
            showMoreInfo={showMoreInfo(e)}
            key={e.id} 
            description={e.description}
            debtors={getDebtors(e.debtors) as User[]}
            amount={e.amount}
            paidByFullName={paidByFullName(e.created_by)}
            ownerOwed={amountOwned}
          />
        )
      })}
    </Box>
  )
}

type IExpenseInfo = {
  data: TBuckBreakerData
  expense: TBuckBreakerExpense
  allParticipants: TBBParticipant[]
}

const ExpenseInfo = ({data, expense, allParticipants}: IExpenseInfo) => {
  const { 
    cancelDialog, 
    openErrorToast, 
    openSuccessToast, 
    openLoadingScreen, 
    closeLoadingScreen,
    showConfirmDialog
  } = useUiStore()
  const [deleteExpense, ] = useDeleteBBExpenseMutation()
  const paidBy = allParticipants.find(p => +p.id === +expense.created_by)
  const debtors = expense.debtors.map(d => allParticipants.find(p => +p.id === +d)).filter(Boolean)
  const removeExpense = () => {
    try {
      openLoadingScreen("Removing Expense")
      deleteExpense({id: data.id, expense_id: expense.id})
      openSuccessToast({message: 'Expense removed successfully'})
      cancelDialog()
    } catch (e) {
      openErrorToast({message: retrieveErrorMessage(e)})
    } finally {
      closeLoadingScreen()
    }
  }

  const removeExpenseWithConfirmation = () => {
    showConfirmDialog({
      dialogBody: 'Are you sure you want to remove this expense?',
      dialogTitle: 'Remove Expense',
      onConfirmAction: removeExpense
    })
  }

  return (
    <ExpenseInfoStateless 
      onCancel={cancelDialog}
      description={expense.description}
      debtors={debtors.map(d => ({full_name: d?.name || '', avatar: d?.avatar || ''}))}
      amount={expense.amount}
      receipt={expense.receipt}
      paidBy={paidBy?.name || ''}
      editExpense={() => {}}
      onDeleteExpense={removeExpenseWithConfirmation}
      hideEditButton
      createdByCurrentUser
    />
  )
}


export default BBExpensesList
