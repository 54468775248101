import React from 'react'
import { ConfirmPaymentStateless } from '../Dashboard/DateRequests/components/BuckBreaker/ConfirmPayment'
import { TBuckBreaker } from '../../utils/types'
import useBuckBreakerBreakdown from './useBuckBreaker'
import { useUiStore } from '../../stores/uiStore'
import { useMarkBBExpenseAsPaidMutation } from '../../services/api'
import { retrieveErrorMessage } from '../../utils/helpers'

type IBBConfirmPayment = {
  bbData: TBuckBreaker
  pid: string
}
const BBConfirmPayment = ({bbData, pid}: IBBConfirmPayment) => {
  const [markAsPaid, ] = useMarkBBExpenseAsPaidMutation()
  const { cancelDialog, openSuccessToast, openErrorToast, openLoadingScreen, closeLoadingScreen } = useUiStore()
  const {uploadReceipt, uploadingReceipt, receipt} = useBuckBreakerBreakdown(bbData)

  const onSubmit = async () => {
    openLoadingScreen("Marking as paid...")
    try {
      await markAsPaid({
        id: bbData.friendly_id, 
        participant_id: pid,
        proof: receipt
      }).unwrap()
      openSuccessToast({message: 'Payment confirmed successfully'})
      cancelDialog()
    } catch (e) {
      openErrorToast({message: retrieveErrorMessage(e)})
    } finally {
      closeLoadingScreen()
    }
  }

  return (
    <ConfirmPaymentStateless 
      uploadReceipt={uploadReceipt}
      uploadingReceipt={uploadingReceipt}
      receipt={receipt || ''}
      onSubmit={onSubmit}
      onCancel={cancelDialog}
    />
  )
}

export default BBConfirmPayment