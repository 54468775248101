import React, { useState } from 'react'
import Box from '@mui/material/Box'
import UploadIcon from '@mui/icons-material/CloudUpload';
import ImageUpload from '../../../../../components/ImageUpload'
import { FormInputWrapper } from '../../../../../components/FormInput/FormInput.styles'
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/CheckCircle';
import Text from '../../../../../components/Text'
import Divider from '@mui/material/Divider'
import { colors } from '../../../../../styles/_var';
import TextWithIcon from '../../../../../components/TextWithIcon';
import { generateRandomNumber, getFilenameFromS3Url, uploadToS3 } from '../../../../../utils/helpers';
import Button from '../../../../../components/Button/Button';
import { useUiStore } from '../../../../../stores/uiStore';
import { AWS_BASE_URL, RECEIPTS_FOLDER } from '../../../../../utils/constants';
import { useMarkExpenseAsPaidMutation } from '../../../../../services/api';



const ConfirmPayment = ({ id, request_id }: {id: number, request_id: string}) => {
  const { cancelDialog } = useUiStore()
  const [markExpenseAsPaid] = useMarkExpenseAsPaidMutation()
  const [receipt, setReceipt] = useState('')
  const [uploadingReceipt, setUploadingReceipt] = useState(false)
  const receiptName = `${RECEIPTS_FOLDER}/${generateRandomNumber(10)}-${generateRandomNumber(3)}.jpg`
  const receiptUrl = `${AWS_BASE_URL}/${receiptName}`

  const uploadReceipt = async (image: string) => {
    setUploadingReceipt(true)
    try {
      await uploadToS3({ fileUrl: image, filename: receiptName})
    } finally {
      setUploadingReceipt(false)
      setReceipt(receiptUrl)
    }
  }

  const onSubmit = () => {
    markExpenseAsPaid({id, receipt, request_id})
    cancelDialog()
  }
  
  return (
    <ConfirmPaymentStateless 
      uploadReceipt={uploadReceipt}
      receipt={receipt}
      uploadingReceipt={uploadingReceipt}
      onSubmit={onSubmit}
      onCancel={cancelDialog}
    />
  )
}

type IConfirmPaymentStateless = {
  uploadReceipt: (image: string) => void
  receipt: string
  uploadingReceipt: boolean
  onSubmit: () => void
  onCancel: () => void
}

export const ConfirmPaymentStateless = ({
  uploadReceipt,
  receipt,
  uploadingReceipt,
  onSubmit,
  onCancel
}: IConfirmPaymentStateless) => {
  return (
    <Box minWidth={300}>
      <Box px={2} py={1.5}>
        <Text weight="semibold" variant="medium">
          Confirm Payment
        </Text>
      </Box>
      <Divider />
      <Box p={2} pb={0}>
        <FormInputWrapper>
          <Text mb={1}>Have a receipt?</Text>
          <ImageUpload
            noPreview
            onImageChange={uploadReceipt}
            maxWidth={300}
            contentArea={({ openFileChooser }) => (
              <Box 
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  border: `solid 1px ${colors.borderColor}`,
                  borderRadius: 5,
                  padding: 8
                }}
              >
                <TextWithIcon 
                  lighten 
                  icon={<UploadIcon color="info" />} 
                  onClick={openFileChooser}
                >
                  {receipt ? getFilenameFromS3Url(receipt) : 'Upload Receipt'}
                </TextWithIcon>
                {(!uploadingReceipt && !!receipt) && <CheckIcon color="success" />}
                {uploadingReceipt && <CircularProgress color="info" size={15} />}
              </Box>
            )}
          />
        </FormInputWrapper>
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button 
          mr={2} 
          variant="outlined" 
          onClick={onCancel}
        >
          <Text useDiv weight="semibold" variant="small">Cancel</Text>
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          <Text useDiv weight="semibold" variant="small" color={colors.white}>
            I've sent the payment
          </Text>
        </Button>
      </Box>
    </Box> 
  )
}

export default ConfirmPayment