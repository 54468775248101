import styled, { css } from 'styled-components'
import { colors, spacing } from '../../styles/_var'
import Text from '../Text'



export const OptionsWrapper = styled(({ horizontal, ...rest }) => <div {...rest} />)`
  ${({ horizontal }) => horizontal && `
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
      min-width: 47%;
      box-sizing: border-box;
    }
  `}
`

export const radioOptionActive = css`
  border-color: ${colors.primary};
  & > div {
    border-color: ${colors.primary};
  }
  & > p {
    color: ${colors.primary};
  }
`

const radioIconSelected = css`
  & > div {
    box-shadow: 0 0 0 1px ${colors.primary};
    background-color: ${colors.primary};
    border: solid 3px ${colors.white};
  }
`

export const RadioButtonWrapper = styled.div``

export const StyledLabel = styled(Text)``

export const RadioOption = styled(({ selected, ...rest }) => <div {...rest} />)`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${spacing.xs};
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.lightgrey};
  padding: 0.5rem ${spacing.xxs};
  transition: all ease .2s;
  background: ${colors.white};

  ${({ selected }) => selected && radioOptionActive}
  ${({ selected }) => selected && radioIconSelected}
  &:hover {
    ${({ selected }) => !selected && radioOptionActive}
  }
`

export const RadioIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.lightgrey};
  display: inline-block;
  margin-right: ${spacing.xxs};
  box-sizing: border-box;
`