import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteForeverRounded';
import { DateRequest } from '../../../../../utils/types'
import { DateExpense, useDeleteDateExpenseMutation, useUpdateDateExpenseMutation } from '../../../../../services/api'
import Text from '../../../../../components/Text'
import { getNameInitials, getPIDOfUser, getUserByPID, shuffle } from '../../../../../utils/helpers'
import Avatar from '../../../../../components/Avatar/Avatar'
import Button from '../../../../../components/Button/Button'
import { useUiStore } from '../../../../../stores/uiStore'
import { useAuthStore } from '../../../../../stores/authStore'
import AddExpenseModal, { onSaveExpenseArgs } from './AddExpenseModal'

type ExpenseInfoProps = {
  dateInfo: DateRequest
  expense: DateExpense
}

const useExpenseInfo = ({ dateInfo, expense }: ExpenseInfoProps) => {
  const { cancelDialog, showDialog, showConfirmDialog } = useUiStore()
  const { currentUser } = useAuthStore()
  const [updateExpenseRequest, ]= useUpdateDateExpenseMutation()
  const [deleteExpenseResult, ] = useDeleteDateExpenseMutation()
  
  const paidBy = getUserByPID(expense.date_participant_id, dateInfo.date_participants)
  const createdByCurrentUser = currentUser.username === paidBy?.username
  const debtors = shuffle(expense.debtors
    .map(pid => getUserByPID(pid, dateInfo.date_participants)))

  const {date_participant_id, ...editPayload} = expense

  const onUpdateExpense = (payload: onSaveExpenseArgs) => {
    const pIdOfUser = getPIDOfUser(currentUser, dateInfo.date_participants)
    if (pIdOfUser) {
      updateExpenseRequest({
        ...payload,
        request_id: dateInfo.request_id,
        date_participant_id: pIdOfUser,
        id: expense.id
      })
      cancelDialog()
    }
  }

  const onDeleteExpense = () => {
    showConfirmDialog({
      dialogTitle: 'You sure?',
      dialogBody: 'Are you sure you want to delete this expense?',
      onConfirmAction: () => {
        deleteExpenseResult({ 
          request_id: dateInfo.request_id,
          id: expense.id
        })
        cancelDialog()
      }
    })

  }

  return {
    cancelDialog,
    showDialog,
    currentUser,
    paidBy,
    debtors,
    createdByCurrentUser,
    onUpdateExpense,
    onDeleteExpense,
    editPayload: {...editPayload, amount: Number(editPayload.amount/100)}
  }
}

const ExpenseInfo = ({ dateInfo, expense }: ExpenseInfoProps) => {
  const { 
    cancelDialog,
    showDialog,
    editPayload,
    onUpdateExpense,
    onDeleteExpense,
    debtors,
    createdByCurrentUser,
    paidBy
  } = useExpenseInfo({ dateInfo, expense })
  const editExpense = () => {
    cancelDialog()
    showDialog({
      dialogBody: (
        <AddExpenseModal 
          dateInfo={dateInfo}
          editPayload={editPayload}
          onSave={onUpdateExpense}
        />
      )
    })
  }

  return (
    <ExpenseInfoStateless 
      description={expense.description}
      paymentInfo={expense.payment_info}
      receipt={expense.receipt}
      amount={expense.amount}
      createdByCurrentUser={createdByCurrentUser}
      onCancel={cancelDialog}
      paidBy={paidBy?.full_name || ''}
      debtors={debtors.map(d => ({
        full_name: d?.full_name || '', 
        avatar: d?.avatar || '', 
        username: d?.username || ''
      }))}
      editExpense={editExpense}
      onDeleteExpense={onDeleteExpense}
    />
  )
}


type IExpenseInfoStateless = {
  description: string
  paymentInfo?: string
  receipt?: string
  amount: number
  createdByCurrentUser: boolean
  paidBy: string
  debtors: Array<{username?: string, full_name: string, avatar: string}>
  onCancel: () => void
  editExpense: () => void
  onDeleteExpense: () => void
  hideEditButton?: boolean
}

export const ExpenseInfoStateless = ({
  description,
  paymentInfo,
  receipt,
  amount,
  createdByCurrentUser,
  onCancel,
  paidBy,
  debtors,
  editExpense,
  onDeleteExpense,
  hideEditButton
}: IExpenseInfoStateless) => {
  return (
    <Box minWidth={320}>
      <Box px={2} py={1.5}>
        <Text variant="medium" weight="semibold">
          Expense Info
        </Text>
      </Box>
      <Divider />
      <Box p={2} display="flex" flexDirection="column" alignItems="center">
        <Text center variant='medium'>
          {description}
        </Text>
        <Text mt={1} center variant='semi-large' weight='semibold'>
          ${Number(amount/100).toFixed(2)}
        </Text>
        {!!paymentInfo && <Text italic center weight="semibold" lighten>{paymentInfo}</Text>}
        {!!receipt && (
          <img 
            style={{marginTop: 10}}
            src={receipt} 
            alt="receipt" width={200} 
            height="autp" 
          />
        )}
      </Box>
      <Box 
        pb={2} 
        px={2} 
        display="flex" 
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box display="flex">
          {debtors.map((debtor, i) => (
            <Avatar key={debtor?.username || i} src={debtor?.avatar} mr={1}>
              {getNameInitials(debtor?.full_name || '')}
            </Avatar>
          ))}
        </Box>
        <Text mt={1} variant="small">Paid By</Text>
        <Text weight="semibold" lighten variant="small">{paidBy}</Text>
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        {createdByCurrentUser ? (
          <Box>
            {!hideEditButton && (
              <IconButton onClick={editExpense} color="info">
                <EditIcon />
              </IconButton>
            )}
            <IconButton onClick={onDeleteExpense} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : <div />}
        <Button variant='outlined' onClick={onCancel}>
          <Text useDiv>Cancel</Text>
        </Button>
      </Box>
    </Box>
  )
}

export default ExpenseInfo
