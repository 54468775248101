import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container'
import { useParams } from 'react-router-dom'
import { DashboardContent } from '../Dashboard/DashboardLayout/DashboardLayout.styles'
import MobileHeader from '../../components/MobileHeader/MobileHeader'
import { useSetHeaderTitle } from '../../hooks/hooks'
import { PageWrapper } from '../../styles/_var'
import { BackdropWrapper, DateRequestInfoWrapper } from '../Dashboard/DateRequests/DateRequests.styles'
import BuckbreakerBackdrop from '../../assets/images/buckbreakerbackdrop.jpg'
import { ActivityBackDrop } from '../Dashboard/PlanActivity/CreateActivity/CreateActivity.styles'
import Text from '../../components/Text';
import { useFetchBuckBreakerQuery } from '../../services/api';
import BuckBreakerBreakdown from './BuckBreakerBreakdown';
import { setPageTitle } from '../../utils/helpers';

const BuckBreaker = () => {
  useSetHeaderTitle('Buck Breaker')
  setPageTitle('Buck Breaker')
  const {buckbreaker_id} = useParams()
  const {data, isLoading} = useFetchBuckBreakerQuery({id: buckbreaker_id || ''})

  return (
    <DashboardContent isMobile>
      <MobileHeader style={{display: 'block'}} />
      <PageWrapper>
        <BackdropWrapper>
          <ActivityBackDrop backdrop={BuckbreakerBackdrop} />
        </BackdropWrapper>
        <DateRequestInfoWrapper style={{top: -65}}>
          <Container>
            <Paper sx={{marginBottom: 3}}>
              <Box p={2}>
                <Text useDiv variant="medium" weight="bold" mb={1}>
                  {data?.payload?.title}
                </Text>
                <Text useDiv variant="medium" mb={1}>
                  {data?.payload?.description}
                </Text>
              </Box>
            </Paper>
          </Container>
        </DateRequestInfoWrapper>
        {isLoading && <LoadingSkeleton />}
        {!!data && <BuckBreakerBreakdown data={data.payload} style={{position: "relative", top: -75}} />}
      </PageWrapper>
    </DashboardContent>
  )
}

export default BuckBreaker

const LoadingSkeleton = () => {
  return (
    <Box p={2} sx={{top: -65, position: "relative"}}>
      <Box display="flex" flexDirection="row" columnGap={3} justifyContent="center">
        <Skeleton variant="rounded" height={40} width={120} />
        <Skeleton variant="rounded" height={40} width={120} />
      </Box>
      <Box mt={4}>
        <Skeleton variant="text" height={30} width={120} />
        <Skeleton variant="text" height={70} />
        <Skeleton variant="text" height={70} />
        <Skeleton variant="text" height={70} />
        <Skeleton variant="text" height={70} />
      </Box>
    </Box>
  ) 
}

