import React, { FunctionComponent } from 'react'
import Text from '../Text'
import { OptionsWrapper, RadioButtonWrapper, RadioIcon, RadioOption, StyledLabel } from './RadioButton.styles'

interface IRadioOption {
  label: string,
  value: string | number | boolean,
}

interface IRadioButton {
  options: IRadioOption[] | string[],
  placeholder?: string,
  value?: string | number | boolean,
  onChange?: (value: any) => void,
  className?: string,
  style?: any,
  horizontal?: boolean,
  "aria-label"?: string, 
}

const RadioButton: FunctionComponent<IRadioButton> = React.memo(({
  placeholder,
  onChange,
  value=null,
  options=[],
  style={},
  className='',
  horizontal,
  ...rest
}) => {
  const renderRadioOption = (option: IRadioOption | string, index: number) => {
    const radioValue = typeof option === 'object' ? option.value : option
    const radioLabel = typeof option === 'object' ? option.label : option

    return (
      <RadioOption
        selected={value === radioValue}
        key={index}
        onClick={() => onChange && onChange(radioValue)}>
        <RadioIcon />
        <Text inline variant="regular">{radioLabel}</Text>
      </RadioOption>
    )
  }

  return (
    <RadioButtonWrapper 
      style={style}
      role="radiogroup"
      className={className}
      {...rest}
    >
      {!!placeholder && <StyledLabel variant="regular" mb={1}>{placeholder}</StyledLabel>}
      <OptionsWrapper horizontal={horizontal}>
        {(options as any).map(renderRadioOption)}
      </OptionsWrapper>
    </RadioButtonWrapper>
  )
})

export default RadioButton
