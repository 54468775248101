import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Text from '../../../../../components/Text';
import { colors } from '../../../../../styles/_var'
import { DateExpense } from '../../../../../services/api';
import { getUserByPID, toCurrency } from '../../../../../utils/helpers';
import { DateRequest, User } from '../../../../../utils/types';
import { useUiStore } from '../../../../../stores/uiStore';
import ExpenseInfo from './ExpenseInfo';
import UsersAvatarGroup from '../../../../../components/UsersAvatarGroup/UsersAvatarGroup';

type ExpenseItemProps = {
  dateInfo: DateRequest
  expense: DateExpense
}

const ExpenseItem = ({ dateInfo, expense }: ExpenseItemProps) => {
  const { showDialog } = useUiStore()
  const paidBy = getUserByPID(expense.date_participant_id, dateInfo.date_participants)
  const debtors = expense.debtors
    .map(pid => getUserByPID(pid, dateInfo.date_participants))
  const ownerOwed = expense.debtors.includes(expense.date_participant_id) 
    ?  expense.amount - (expense.amount/debtors.length)
    : expense.amount

  const showMoreInfo = () => {
    showDialog({
      dialogBody: (
        <ExpenseInfo 
          dateInfo={dateInfo} 
          expense={expense} 
        />
      )
    })
  }

  return (
    <ExpenseItemStateLess 
      description={expense.description}
      debtors={debtors as User[]}
      amount={expense.amount}
      paidByFullName={paidBy?.full_name || ''}
      showMoreInfo={showMoreInfo}
      ownerOwed={ownerOwed}
    />
  )
}


type IExpenseItemStateless = {
  description: string
  debtors: User[]
  ownerOwed: number
  paidByFullName: string
  showMoreInfo: () => void
  amount: number
}

export const ExpenseItemStateLess = ({
  description,
  debtors,
  ownerOwed,
  paidByFullName,
  showMoreInfo,
  amount
}: IExpenseItemStateless) => {
  return (
    <>
      <MenuItem sx={{padding: 0}} onClick={showMoreInfo}>
        <Box my={1} display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            <UsersAvatarGroup users={debtors.filter(u => !!u) as User[]} />
            <Box ml={1}>
              <Text variant='medium'>{description}</Text>
              <Text variant='small' lighten weight="semibold">
                Paid By {paidByFullName}
              </Text>
            </Box>
          </Box>
          <Box>
            <Text variant='semi-large' weight="semibold" color={colors.primary}>
              {toCurrency(amount)}
            </Text>
            <Text variant='small' right weight='semibold' color={colors.danger}>
              owed: {toCurrency(ownerOwed)}
            </Text>
          </Box>
        </Box>
      </MenuItem>
      <Divider />
    </>
  )
}

export default ExpenseItem

