import React from 'react'
import Drawer from '../../../components/Drawer/Drawer'
import Box from '@mui/material/Box'
import SimpleForm from '../../../components/SimpleForm'
import Input from '../../../components/Input'
import { useCreateBuckBreakerMutation } from '../../../services/api'
import { useUiStore } from '../../../stores/uiStore'
import { retrieveErrorMessage } from '../../../utils/helpers'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../utils/constants'

type BuckBreakerDrawerProps = {
  isOpen: boolean,
  closeDrawer?: () => void,
}

const BuckBreakerDrawer = ({
  isOpen,
  closeDrawer=() => {},
}: BuckBreakerDrawerProps) => {
  const navigate = useNavigate()
  const [createBuckBreaker, {isLoading}] = useCreateBuckBreakerMutation()
  const {openErrorToast, openSuccessToast} = useUiStore()
  const onSubmit = async (values: {title: string, description: string}) => {
    try {
      const response = await createBuckBreaker(values).unwrap()
      const buckbreakerId = response.payload.friendly_id
      openSuccessToast({message: 'BuckBreaker created successfully'}) 
      closeDrawer()
      navigate(routes.BUCK_BREAKER.replace(':buckbreaker_id', buckbreakerId))
    } catch (error) {
      openErrorToast({message: retrieveErrorMessage(error)})
    }
  }

  return (
    <Drawer
      disableEnforceFocus
      showCloseIcon
      anchor='bottom'
      open={isOpen}
      onOpen={() => {}}
      onClose={closeDrawer}
      header='Create BuckBreaker'
    >
      <BuckBreakerDrawerForm loading={isLoading} onSubmit={onSubmit} />
    </Drawer>
  )
}


type IBuckBreakerDrawerForm = {
  loading: boolean,
  onSubmit: (values: {title: string, description: string}) => void
}

const BuckBreakerDrawerForm = ({loading, onSubmit}: IBuckBreakerDrawerForm) => {
  const initialValues = {
    title: '',
    description: ''
  }

  const formFields = [
    {
      name: 'title',
      placeholder: 'Enter title',
      size: 'small',
      required: true,
      component: Input
    },
    {
      name: 'description',
      placeholder: 'Enter description',
      size: 'small',
      required: true,
      component: Input
    }
  ]
  return (
    <Box p={2}>
      <SimpleForm<{title: string, description: string}>
        animateForm
        initialValues={initialValues}
        formFields={formFields}
        actionButtons={
          {
            isSubmit: true,
            variant: 'contained',
            label: 'Create BuckBreaker',
            type: 'submit',
            color: 'primary',
            loading: loading
          }
        }
        submitForm={onSubmit}
      />
    </Box>
  )
}

export default BuckBreakerDrawer
