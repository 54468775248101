import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Auth from '../utils/auth'
import { generateRandomNumber, isMobile } from '../utils/helpers'
import {
  DateRequestResponse,
  User,
  DateScheduleResponse,
  SupportMessage,
  SupportConversation,
  FavouriteActivity,
  UserProfile,
  DateRequest,
  FriendCategory,
  FriendRequest,
  Friend,
  Notification,
  PaginationInfo,
  MessageType,
  DateTimeslot,
  DateSchedule,
  DAYS_OF_THE_WEEK,
  UserDetails,
  NotificationSettingType,
  ParticipantRequest,
  FriendshipStatus,
  OpenDateRequest,
  SupplySuggestion,
  Supply,
  TBuckBreaker,
  TBuckBreakerExpense
} from '../utils/types'
import { showToastMessage } from '../stores/uiStore'
import { retrieveErrorMessage } from '../utils/helpers'
import { selectCurrentUser, updateNotificationSettings } from '../stores/authStore'
import { OpenDateRequestsFormValues } from '../pages/Dashboard/OpenDateRequests/OpenDateRequests.hooks'
import { store } from '../store'

export type LoginResponse = {
  user: User,
  token: string,
}

export type LoginArgs = {
  username: string,
  password: string
}

export type RegisterArgs = {
  full_name: string,
  password: string,
  username: string,
  email: string
}

export type PreferredDatingActivity = {
  description: string,
  favourite_places: string
}

export type SupportMessageRequest = {
  subject: string,
  message: string
}

export type SupportMessageResponse = {
  message: string
}

export type ParticipantRequestResponse = {
  payload: ParticipantRequest[]
}

export type ParticipantRequestRequest = {
  request_id: string,
  requests: Array<{
    contact: string,
    name: string
  }>
}
export type RegisterResponse = {}

export type GetConversationRequest = {id: number}

export type GetConversationResponse = {
  payload: SupportConversation[]
}

export type ProfileResponse = {
  payload: UserProfile
}

export type UnavailableSlot = {
  start_date: string,
  end_date: string
}

export type AvailabilityResponse = {
  payload: {
    schedule: DateSchedule[],
    unavailable: UnavailableSlot[] 
  }
}

export type DateRequestsRequest = {
  request_id: string,
  start_date: string,
  end_date: string,
  location: string, 
  activity: string,
  social_media?: string,
  description?: string,
  backdrop_image?: string
}

export type RescheduleDateRequest = {
  start_date: string,
  end_date: string,
  id: string
}

export type NotificationResponse = {
  payload: Notification[],
  pagination_info: PaginationInfo
}

export type ChatMessageRequest = {
  request_id: string,
  message: string,
  message_type?: string
}

export type ResetPasswordRequest = {
  email: string,
  reset_token: string,
  new_password: string
}

export type AddParticipantRequest = {
  request_id: string,
  participants: Array<{contact: string, name: string}>
}

export type TeamScoreboardData = {
  teams: string[]
  members: Record<string, number[]>
  results: Record<string, string[]>
  winning_team?: string | null
}

export type ScoreboardData = Record<string, string[]> | TeamScoreboardData

export type DateScoreboardResponse = {
  payload: Array<DateMemoryScoreboard>
}

export type DateCommentsResponse = {
  payload: Array<DateComment>
}

export type AddDateScoreboardRequest = {
  winner?: number,
  request_id: string,
  scores: string,
  memory_type: string,
  scoreboard_id?: number,
  label?: string
}

export type AddDateCommentRequest = {
  memory_type: string,
  comment: string,
  request_id: string
}

export type AddCommentResponse = {
  message: string,
  payload: DateComment
}

export type AddDateMediaRequest = {
  memory_type: string,
  url: string[],
  caption?: string
  request_id: string
}

export type AddDateMediaResponse = {
  message: string,
  payload: DateMemoryMedia[]
}

export type DateMemoryScoreboard = {
  memory_object: {
    winner?: Participant,
    scores_data: ScoreboardData,
    label?: string,
    id?: number
  }
}

export type DateComment = {
  id: number,
  date_participant_id: string,
  memory_object: {
    id: number,
    comment: string,
    created_at: string
  }
}

export type DateMedia = {
  id: number,
  url: string,
  caption?: string
}

export type DateMemoryMedia = {
  id: number,
  date_participant_id: string,
  memory_object: DateMedia
}

export type DateMediaResponse = {
  payload: DateMemoryMedia[]
}

export type DateMemoryKind = 'photo' | 'scoreboard' | 'comment' | 'all'

export type ParticipantResponse = 'pending' | 'accepted' | 'declined'

export type FixedParticipant = {
  id: number,
  response?: ParticipantResponse,
  updated_at?: string,
  friendship_status?: FriendshipStatus
  note?: string
  avatar?: string
}

export type Participant = ({
  user: User,
  name?: never,
  contact?: never,
} | {
  name: string,
  contact: string,
  user?: never,
}) & FixedParticipant

export type AddParticipantResponse = {
  payload: Participant[]
}

export type DeleteParticipantRequest = {
  request_id: string,
  participant_id: number
}

export type DateExpense = {
  id: number,
  debtors: number[],
  description: string,
  date_participant_id: number,
  receipt?: string,
  payment_info?: string
  amount: number
}

export type DateExpenseResponse = {
  payload: {
    expenses: DateExpense[],
    expense_payments: DateExpensePayment[]
  }
}

export type DateExpensePayment = {
  id: number,
  date_participant_id: number,
  receipt?: string,
  payment_status: 'pending' | 'paid',
  amount_owed: number,
  amount_spent: number
}

export type DateExpensePaymentResponse = {
  payload: DateExpensePayment[]
}

export type DateExpenseRequest = {
  request_id: string,
} & Omit<DateExpense, 'id'>

export type DateExpenseUpdateOrDeleteResponse = {
  message: string,
  payload?: DateExpense
}

export type DateExpenseUpdateOrDeleteRequest = {
  id: number,
} & DateExpenseRequest

export type OpenRequestPayload = OpenDateRequestsFormValues

export const baseUrl = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_API_URL : (isMobile() ? 'http://10.0.0.55:8000/api/v1' : 'http://localhost:8000/api/v1')
// export const baseUrl = 'http://localhost:8000/api/v1'

export const gusiberiApi = createApi({
  reducerPath: 'gusiberiApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = Auth.fetchToken()
      if (token) headers.set("Authorization", `Bearer ${token}`)
    }
  }),
  tagTypes: [
    'approved_requests',
    'pending_requests',
    'pending_friend_requests',
    'all_friends',
    'user_profile',
    'fetch_friendships',
    'friend_categories',
    'date_expense_payments',
    'fetch_date_media',
    'active_pending_requests',
    'fetch_date_request',
    'fetch_friends_open_date_request',
  ],
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginArgs>({
      query: (body) => ({ url: 'login', method: 'POST', body }),
    }),
    register: builder.mutation<LoginResponse, RegisterArgs>({
      query: (body) => ({ url: 'register', method: 'POST', body })
    }),
    googleAuth: builder.mutation<LoginResponse, {id_token: string, params?: any}>({
      query: body => ({ url: 'google_auth', method: 'POST', body })
    }),
    currentUser: builder.query<{payload: UserDetails}, {}>({
      query: () => 'user_details'
    }),
    setUserProfileInfo: builder.mutation<{message: string}, Partial<User>>({
      query: (body) => ({ url: 'user_profile', method: 'POST', body })
    }),
    preferredDatingActivity: builder.query<{payload: PreferredDatingActivity[]}, {}>({
      query: () => 'favourite_activities'
    }),
    dateSchedule: builder.query<DateScheduleResponse, {}>({
      query: () => 'date_schedules',
      transformResponse: (response: {payload: DateSchedule[]}): DateScheduleResponse => {
        const data = response.payload.reduce((prev, date) => {
          const day = DAYS_OF_THE_WEEK[date.day_of_the_week - 1]
          if (!prev[day]) {
            prev[day] = date
          }
          return prev
        }, {} as DateScheduleResponse)
        return data
      }
    }),
    fetchUpcomingDates: builder.query<DateRequestResponse, {page?: number}>({
      query: params => ({ url: 'date_requests/approved', params }),
      providesTags: ['approved_requests']
    }),
    fetchPastDates: builder.query<DateRequestResponse, {page?: number}>({
      query: params => ({ url: 'date_requests/approved?type=past', params }),
      providesTags: ['approved_requests']
    }),
    deactivateDay: builder.mutation<{message: string}, {day_of_week: number}>({
      query: (body) => ({ url: 'date_schedules/deactivate', method: 'POST', body })
    }),
    activateDay: builder.mutation<{message: string}, {day_of_week: number}>({
      query: (body) => ({ url: 'date_schedules/activate', method: 'POST', body })
    }),
    addSchedule: builder.mutation<{payload: DateTimeslot}, Omit<DateTimeslot, "id" | "day_of_the_week">>({
      query: (body) => ({ url: 'date_schedules', method: 'POST', body })
    }),
    deleteSchedule: builder.mutation<{message: string}, {id: number}>({
      query: (body) => ({ url: `date_schedules/${body.id}`, method: 'DELETE' })
    }),
    changePassword: builder.mutation<{message: string}, {old_password: string, new_password: string}>({
      query: (body) => ({ url: 'change_password', method: 'POST', body })
    }),
    deleteAccount: builder.mutation<{message: string}, {password: string}>({
      query: (body) => ({ url: 'delete_account', method: 'POST', body })
    }),
    sendSupportMessage: builder.mutation<SupportMessageResponse, SupportMessageRequest>({
      query: (body) => ({ url: 'supports', method: 'POST', body })
    }),
    fetchSupportIssues: builder.query<{payload: SupportMessage[]}, {}>({
      query: () => 'supports'
    }),
    fetchSupportConversation: builder.query<GetConversationResponse, GetConversationRequest>({
      query: params => ({ url: `supports/${params.id}/messages`})
    }),
    postSupportConversation: builder.mutation<{payload: SupportConversation}, {message: string, id: number}>({
      query: (body) => ({ url: `supports/${body.id}/messages`, method: 'POST', body })
    }),
    fetchFavouriteActivities: builder.query<{payload: FavouriteActivity[]}, {}>({
      query: () => 'favourite_activities'
    }),
    postFavouriteActivities: builder.mutation<{payload: FavouriteActivity}, Partial<FavouriteActivity>>({
      query: body => ({ url: 'favourite_activities', method: 'POST', body })
    }),
    deleteFavouriteActivity: builder.mutation<{message: string}, {id: number}>({
      query: body => ({ url: `favourite_activities/${body.id}`, method: 'DELETE', body })
    }),
    updateFavouriteActivities: builder.mutation<{payload: FavouriteActivity}, FavouriteActivity>({
      query: body => ({ url: `favourite_activities/${body.id}`, method: 'PUT', body })
    }),
    searchUsers: builder.query<{payload: User[]}, {query: string}>({
      query: params => ({ url: `search?q=${params.query}` })
    }),
    profile: builder.query<ProfileResponse, {username: string}>({
      query: params => ({ url: `users/${params.username}/profile` }),
      providesTags: ['user_profile']
    }),
    availability: builder.query<AvailabilityResponse, {username: string}>({
      query: params => ({ url: `users/${params.username}/availability` })
    }),
    sendDateRequest: builder.mutation<{payload: DateRequest}, Omit<DateRequestsRequest, 'request_id'>>({
      query: body => ({ url: 'date_requests', method: 'POST', body }),
      invalidatesTags: ["approved_requests"]
    }),
    scheduleAnother: builder.mutation<{payload: DateRequest}, {request_id: string, start_date: string, end_date: string}>({
      query: body => ({ url: `date_requests/${body.request_id}/schedule_another`, method: 'POST', body }),
      invalidatesTags: ["approved_requests"]
    }),
    updateSentDateRequest: builder.mutation<{message: string}, DateRequestsRequest>({
      query: body => ({ url: `date_requests/${body.request_id}`, method: 'PUT', body})
    }),
    fetchDateRequest: builder.query<{payload: DateRequest}, { request_id: string }>({
      query: (params) => `date_requests/${params.request_id}`,
      providesTags: ["fetch_date_request"]
    }),
    fetchActivePendingRequest: builder.query<{payload: DateRequest[]}, {}>({
      query: () => ({ url: `date_requests?type=relevant_pending` }),
      providesTags: ["active_pending_requests"]
    }),
    fetchPastPendingRequest: builder.query<{payload: DateRequest[]}, {}>({
      query: () => ({ url: `date_requests?type=past_pending` }),
    }),
    fetchSentRequests: builder.query<{payload: DateRequest[]}, {}>({
      query: () => ({ url: `date_requests?type=request_sent` }),
      providesTags: ['pending_requests']
    }),
    fetchReceivedRequests: builder.query<{payload: DateRequest[]}, {}>({
      query: () => ({ url: `date_requests?type=request_recieved` }),
      providesTags: ['pending_requests']
    }), 
    acceptDateRequest: builder.mutation<{message: string}, {id: string, message?: string}>({
      query: body => ({ url: `date_requests/${body.id}/accept`, method: 'POST' }),
      invalidatesTags: ['pending_requests', 'approved_requests', 'active_pending_requests']
    }),
    declineDateRequest: builder.mutation<{message: string}, {id: string, message?: string}>({
      query: body => ({ url: `date_requests/${body.id}/decline`, method: 'POST', body }),
      invalidatesTags: ['pending_requests', 'active_pending_requests']
    }),
    rescheduleDateRequest: builder.mutation<{message: string}, RescheduleDateRequest>({
      query: body => ({ url: `date_requests/${body.id}/reschedule`, method: 'POST', body }),
      invalidatesTags: ['approved_requests'],
    }),
    addReviewRequest: builder.mutation<{payload: DateRequest}, {review: string, id: string}>({
      query: body => ({ url: `date_requests/${body.id}/review`, method: 'POST', body })
    }),
    sendFriendRequest: builder.mutation<{message: string}, {sent_to: string}>({
      query: body => ({ url: 'friend_requests', method: 'POST', body }),
      invalidatesTags: ['user_profile']
    }),
    deleteFriendGroupRequest: builder.mutation<{message: string}, {id: number}>({
      query: body => ({ url: `friend_categories/${body.id}`, method: 'DELETE', body }),
      invalidatesTags: ['friend_categories']
    }),
    createFriendGroupRequest: builder.mutation<{payload: FriendCategory}, {name: string}>({
      query: body => ({ url: 'friend_categories', method: 'POST', body }),
      invalidatesTags: ['friend_categories']
    }),
    fetchFriendGroupsRequest: builder.query<{payload: FriendCategory[]}, {}>({
      query: () => 'friend_categories',
      providesTags: ['friend_categories']
    }),
    renameFriendGroupRequest: builder.mutation<{message: string}, {id: number, name: string}>({
      query: body => ({ url: `friend_categories/${body.id}`, method: 'PUT', body }),
      invalidatesTags: ['friend_categories']
    }),
    fetchPendingFriendRequests: builder.query<{payload: FriendRequest[]}, {}>({
      query: () => 'friend_requests',
      providesTags: ['pending_friend_requests']
    }),
    fetchDeclinedFriendRequests: builder.query<{payload: FriendRequest[]}, {}>({
      query: () => 'friend_requests?request_type=declined',
    }),
    getFriendsRequest: builder.query<{payload: Friend[]}, {}>({
      query: () => 'friendships',
      providesTags: ['fetch_friendships']
    }),
    acceptFriendRequest: builder.mutation<{message: string}, {id: number}>({
      query: body => ({ url: `friend_requests/${body.id}/accept`, method: 'POST' }),
      invalidatesTags: ['pending_friend_requests', 'fetch_friendships', 'user_profile']
    }),
    removeFriendRequest: builder.mutation<{message: string}, {friend_username: string}>({
      query: body => ({ url: `friendships/unfriend`, method: 'POST', body }),
      invalidatesTags: ['user_profile', 'fetch_friendships']
    }),
    declineFriendRequest: builder.mutation<{message: string}, {id: number}>({
      query: body => ({ url: `friend_requests/${body.id}/decline`, method: 'POST' }),
      invalidatesTags: ['pending_friend_requests']
    }),
    assignFriendsToGroupRequest: builder.mutation<{message: string}, {friend_username: string[], category_id: number}>({
      query: body => ({ url: 'friendships/assign_to_category', method: 'POST', body }),
      invalidatesTags: ['friend_categories']
    }),
    fetchNotifications: builder.query<NotificationResponse, {}>({
      query: () => 'notifications'
    }),
    markNotificationAsSeen: builder.mutation<{message: string}, {id: number}>({
      query: body => ({ url: `notifications/${body.id}/mark_as_seen`, method: 'POST' })
    }),
    markAllNotificationAsSeen: builder.mutation<{message: string}, {}>({
      query: () => ({ url: 'notifications/mark_all_as_seen', method: 'POST' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchNotifications", {}, notifications => {
            notifications.payload = notifications.payload.map(n => ({...n, seen: true}))
          })
        )
        queryFulfilled
          .then(() => {
            dispatch(showToastMessage({message: "All notifications marked as seen", severity: 'success'}))
          })
          .catch(e => {
            dispatch(showToastMessage({message: retrieveErrorMessage(e), severity: 'error'}))
            patchResult.undo()
        })
      }
    }),
    sendChatMessage: builder.mutation<{payload: MessageType}, ChatMessageRequest>({
      query: body => ({ url: `date_requests/${body.request_id}/date_discussions`, method: 'POST', body })
    }),
    fetchChatMessages: builder.query<{payload: MessageType[], pagination_info: PaginationInfo}, {request_id: string, page?: number, offset?: string}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_discussions?page=${body.page || 1}&offset=${body.offset || ''}` })
    }),
    markMessagesAsSeen: builder.mutation<{message: string}, {request_id: string}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_discussions/mark_as_seen`, method: 'POST', body })
    }),
    resendActivation: builder.mutation<{message: string}, {}>({
      query: body => ({ url: 'users/send_activation_link', method: 'POST', body })
    }),
    activateUser: builder.mutation<{message: string}, {activation_code: string}>({
      query: body => ({ url: 'activate', body, method: 'POST' })
    }),
    resetPasswordRequest: builder.mutation<{message: string}, {email: string}>({
      query: body => ({ url: 'password_reset_request', body, method: 'POST' })
    }),
    resetPassword: builder.mutation<{message: string}, ResetPasswordRequest>({
      query: body => ({ url: 'password_reset', body, method: 'POST' })
    }),
    addParticipant: builder.mutation<AddParticipantResponse, AddParticipantRequest>({
      query: body => ({ url: `date_requests/${body.request_id}/date_participants`, body, method: 'POST' }),
      onQueryStarted: async ({request_id }, { dispatch, queryFulfilled}) => {
        try {
          const response = await queryFulfilled
          dispatch(
            gusiberiApi.util.updateQueryData("fetchSentRequests", {}, activities => {
              activities.payload = activities.payload.map(activity => {
                if (activity.request_id === request_id) {
                  return {...activity, date_participants: [...(activity.date_participants || []), ...response.data.payload]}
                }
                return activity
              })
            })
          )

          dispatch(
            gusiberiApi.util.updateQueryData("fetchDateRequest", { request_id }, dateRequest => {
              dateRequest.payload = {...dateRequest.payload, date_participants: [...(dateRequest.payload.date_participants || []), ...response.data.payload]}
            })
          )
        } catch (e) {
          dispatch(showToastMessage({message: retrieveErrorMessage(e), severity: 'error'}))
        }
      }
    }),
    resendParticipantInvite: builder.mutation<{message: string}, {request_id: string, pid: number}>({
      query: body => ({url: `date_requests/${body.request_id}/date_participants/${body.pid}/resend_invite`, method: 'POST' }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        dispatch(showToastMessage({ message: "invite resent!", severity: "success" }))
        queryFulfilled.catch(e => dispatch(showToastMessage({ message: retrieveErrorMessage(e), severity: "error"})))
      }
    }),
    removeParticipant: builder.mutation<{message: string}, DeleteParticipantRequest>({
      query: body => ({ url: `date_requests/${body.request_id}/date_participants/${body.participant_id}`, method: 'DELETE' })
    }),
    fetchDateScoreboardRequest: builder.query<DateScoreboardResponse, {request_id: string}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_memories?kind=scoreboard` })
    }),
    saveDateScoreboardRequest: builder.mutation<{message: string, payload: DateMemoryScoreboard}, AddDateScoreboardRequest>({
      query: body => ({ url: `date_requests/${body.request_id}/date_memories`, body, method: 'POST' })
    }),
    fetchDateComments: builder.query<DateCommentsResponse, {request_id: string}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_memories?kind=comment` })
    }),
    saveDateComments: builder.mutation<AddCommentResponse, AddDateCommentRequest>({
      query: body => ({ url: `date_requests/${body.request_id}/date_memories`, body, method: 'POST' })
    }),
    deleteMemoryRequest: builder.mutation<{message: string}, {request_id: string, memory_id: number}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_memories/${body.memory_id}`, method: 'DELETE'})
    }),
    fetchDateMedia: builder.query<DateMediaResponse, {request_id: string}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_memories?kind=photo` }),
      providesTags: ["fetch_date_media"]
    }),
    saveDateMedia: builder.mutation<AddDateMediaResponse, AddDateMediaRequest>({
      query: body => ({ url: `date_requests/${body.request_id}/date_memories`, body, method: 'POST'})
    }),
    saveDateExpense: builder.mutation<DateExpenseResponse, DateExpenseRequest>({
      query: body => ({ url: `date_requests/${body.request_id}/date_expenses`, body, method: 'POST'}),
      invalidatesTags: ['date_expense_payments'],
      onQueryStarted: async ({request_id, ...patch}, { dispatch, queryFulfilled}) => {
        const fakeId = generateRandomNumber(5)
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchDateExpense", {request_id}, expenses => {
            const tempPatch = {...patch, id: fakeId}
            expenses.payload = {
              expenses: [tempPatch, ...expenses.payload.expenses],
              expense_payments: expenses.payload.expense_payments
            }
          })
        )

        try {
          const response = await queryFulfilled
          dispatch(
            gusiberiApi.util.updateQueryData("fetchDateExpense", {request_id}, expenses => {
              expenses.payload = response.data.payload
            })
          )
        } catch (e) {
          dispatch(showToastMessage({severity: "error", message: retrieveErrorMessage(e)}))
          patchResult.undo()
        }
      }
    }),
    updateDateExpense: builder.mutation<DateExpenseResponse, DateExpenseUpdateOrDeleteRequest>({
      query: body => ({ url: `date_requests/${body.request_id}/date_expenses/${body.id}`, body, method: 'PUT'}),
      invalidatesTags: ['date_expense_payments'],
      onQueryStarted: async ({request_id, ...patch}, { dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchDateExpense", {request_id}, expenses => {
            expenses.payload = {
              expenses: expenses.payload.expenses.map(e => e.id === patch.id ? patch : e),
              expense_payments: expenses.payload.expense_payments
            }
          })
        )

        queryFulfilled
          .then(response => {
            dispatch(
              gusiberiApi.util.updateQueryData("fetchDateExpense", {request_id}, expenses => {
                expenses.payload = response.data.payload
              })
            )
          })
          .catch(e => {
            dispatch(showToastMessage({severity: "error", message: retrieveErrorMessage(e)}))
            patchResult.undo()
          })
      }
    }),
    deleteDateExpense: builder.mutation<DateExpenseResponse, {request_id: string, id: number}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_expenses/${body.id}`, method: 'DELETE'}),
      invalidatesTags: ['date_expense_payments'],
      onQueryStarted: async ({id, request_id}, { dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchDateExpense", {request_id}, expenses => {
            expenses.payload = {
              expenses: expenses.payload.expenses.filter(e => e.id !== id),
              expense_payments: expenses.payload.expense_payments
            }
          })
        )
        queryFulfilled
          .then(response => {
            dispatch(
              gusiberiApi.util.updateQueryData("fetchDateExpense", {request_id}, expenses => {
                expenses.payload = response.data.payload
              })
            )
          })
          .catch(e => {
            patchResult.undo()
            dispatch(showToastMessage({severity: "error", message: retrieveErrorMessage(e)}))
          })
      }
    }),
    fetchDateExpense: builder.query<DateExpenseResponse, {request_id: string}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_expenses` })
    }),
    markExpenseAsPaid: builder.mutation<DateExpenseResponse, {request_id: string, receipt?: string, id: number}>({
      query: body => ({url: `date_requests/${body.request_id}/date_expenses/mark_as_paid`, body: {receipt: body.receipt}, method: 'POST'}),
      onQueryStarted: async ({request_id, ...patch}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchDateExpense", {request_id}, expenses => {
            expenses.payload = {
              expenses: expenses.payload.expenses,
              expense_payments: expenses.payload.expense_payments.map(e => e.id === patch.id ? {...e, ...{payment_status: "paid"}} : e)
            }
          })
        )

        queryFulfilled
          .then(response =>{
            dispatch(
              gusiberiApi.util.updateQueryData("fetchDateExpense", {request_id}, expenses => {
                expenses.payload = response.data.payload
              })
            )
          })
          .catch(e => {
            dispatch(showToastMessage({severity: "error", message: retrieveErrorMessage(e)}))
            patchResult.undo()
          })
      }
    }),
    setNotificationSettings: builder.mutation<{message: string}, {field: NotificationSettingType, value: boolean}>({
      query: body => ({url: 'notification_settings/set', body, method: 'POST'}),
      onQueryStarted: async ({field, value}, { dispatch, queryFulfilled }) => {
        dispatch(updateNotificationSettings({[field]: value}))
        queryFulfilled.catch(e => {
          dispatch(updateNotificationSettings({[field]: !value}))
          dispatch(showToastMessage({severity: "error", message: retrieveErrorMessage(e)}))
        })
      }
    }),
    deleteDateRequest: builder.mutation<{message: string}, {request_id: string}>({
      query: body => ({ url: `date_requests/${body.request_id}`, method: 'DELETE' }),
      invalidatesTags: ['approved_requests']
    }),
    sendParticipantRequest: builder.mutation<ParticipantRequestResponse, ParticipantRequestRequest>({
      query: body => ({ url: `date_requests/${body.request_id}/date_participant_requests`, method: 'POST', body })
    }),
    updateParticipantRequest: builder.mutation<{message: string}, {request_id: string, prequest_id: number, approval_status: ParticipantResponse}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_participant_requests/${body.prequest_id}`, method: 'PUT', body }),
      onQueryStarted: async ({request_id, prequest_id, approval_status}, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchDateRequest", {request_id}, dateRequest => {
            dateRequest.payload.date_participant_requests = dateRequest.payload.date_participant_requests?.map(p => p.id === prequest_id ? {...p, approval_status} : p)
          })
        )
        
        queryFulfilled.then(() => {
          dispatch(showToastMessage({message: "status updated!", severity: "success"}))
        }).catch(e => {
          dispatch(showToastMessage({message: retrieveErrorMessage(e), severity: "error"}))
          patchResult.undo()
        })
      },
      invalidatesTags: ["fetch_date_request"]
    }),
    deleteParticipantRequest: builder.mutation<{message: string}, {request_id: string, prequest_id: number}>({
      query: body => ({ url: `date_requests/${body.request_id}/date_participant_requests/${body.prequest_id}`, method: 'DELETE' })
    }),
    fetchOpenDateRequests: builder.query<{payload: OpenDateRequest[]}, {}>({
      query: () => 'open_date_requests'
    }),
    deleteOpenDateRequest: builder.mutation<{message: string}, {slug: string}>({
      query: body => ({ url: `open_date_requests/${body.slug}`, method: 'DELETE' }),
      onQueryStarted: async ({slug}, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchOpenDateRequests", {}, requests => {
              requests.payload = requests.payload.filter(r => r.slug !== slug)
            })
          )
        })
      },
      invalidatesTags: ['fetch_friends_open_date_request']
    }),
    updateOpenDateRequest: builder.mutation<{payload: OpenDateRequest}, {slug?: string} & OpenDateRequestsFormValues>({
      query: body => ({ url: `open_date_requests/${body.slug}`, method: 'PUT', body }),
      onQueryStarted: async ({slug, ...patch}, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchOpenDateRequests", {}, requests => {
            requests.payload = requests.payload.map(r => r.slug === slug ? {...r, ...patch} : r)
          })
        )

        queryFulfilled.catch(e => {
          patchResult.undo()
        })
      }
    }),
    addSuppliesSuggestion: builder.mutation<{payload: Required<SupplySuggestion>}, {request_id: string, payload: SupplySuggestion}>({
      query: body => ({ url: `date_requests/${body.request_id}/supplies_suggestions`, method: 'POST', body: body.payload }),
      onQueryStarted: async ({request_id, payload}, { dispatch, queryFulfilled }) => {
        const tempId = generateRandomNumber(5)
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchDateRequest", {request_id}, request => {
            request.payload = {...request.payload, supplies_suggestions: [...(request.payload.supplies_suggestions || []), {...payload, id: tempId}]}
          })
        )

        queryFulfilled.then(response => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchDateRequest", {request_id}, request => {
              const suggestions = (request.payload.supplies_suggestions || []).map(s => s.id === tempId ? response.data.payload : s)
              request.payload = {...request.payload, supplies_suggestions: suggestions}
            })
          )
        }).catch(e => {
          patchResult.undo()
        })
      }
    }),
    removeSuppliesSuggestion: builder.mutation<{message: string}, {request_id: string, id: number}>({
      query: body => ({ url: `date_requests/${body.request_id}/supplies_suggestions/${body.id}`, method: 'DELETE' }),
      onQueryStarted: async ({request_id, id}, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchDateRequest", {request_id}, request => {
            request.payload = {...request.payload, supplies_suggestions: request.payload.supplies_suggestions?.filter(s => s.id !== id)}
          })
        )

        queryFulfilled.catch(e => {
          patchResult.undo()
        })
      }
    }),
    pickSuppliesSuggestion: builder.mutation<{payload: Required<Supply>}, {request_id: string, id: number, suggestion?: SupplySuggestion, participantId?: number}>({
      query: body => ({ url: `date_requests/${body.request_id}/supplies_suggestions/${body.id}/select`, method: 'POST' }),
      onQueryStarted: async ({request_id, id, suggestion, participantId}, { dispatch, queryFulfilled }) => {
        if (!suggestion || !participantId) return
        const tempId = generateRandomNumber()
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchDateRequest", {request_id}, request => {
            request.payload = {
              ...request.payload, 
              supplies: [...(request.payload.supplies || []), {
                item: suggestion.item, 
                additional_info: suggestion.additional_info, 
                id: tempId,
                date_participant_id: participantId
              }]}
          })
        )

        queryFulfilled.then(result => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchDateRequest", {request_id}, request => {
              request.payload = {
                ...request.payload,
                supplies: request.payload.supplies?.map(s => s.id === tempId ? result.data.payload : s)
              }
            })
          )
        }).catch(e => {
          patchResult.undo()
        })
      }
    }),
    createOpenDateRequest: builder.mutation<{payload: OpenDateRequest}, OpenDateRequestsFormValues>({
      query: body => ({ url: 'open_date_requests', method: 'POST', body }),
      invalidatesTags: ['fetch_friends_open_date_request'],
    }),
    acceptOpenRequestInterest: builder.mutation<{message: string}, {slug: string, id: number}>({
      query: body => ({ 
        url: `open_date_requests/${body.slug}/open_date_request_interests/${body.id}/accept`, 
        method: 'POST' 
      }),
      onQueryStarted: async ({slug, id}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchOpenDateRequest", {slug}, request => {
            request.payload = {...request.payload, open_date_request_interests: request.payload.open_date_request_interests?.map(i => i.id === id ? {...i, status: "accepted"} : i)}
          })
        )
        queryFulfilled.catch(e => {
          patchResult.undo()
        })
      },
    }),
    rejectOpenRequestInterest: builder.mutation<{message: string}, {slug: string, id: number}>({
      query: body => ({
        url: `open_date_requests/${body.slug}/open_date_request_interests/${body.id}/reject`,
        method: 'POST'
      }),
      onQueryStarted: async ({slug, id}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchOpenDateRequest", {slug}, request => {
            request.payload = {...request.payload, open_date_request_interests: request.payload.open_date_request_interests?.map(i => i.id === id ? {...i, status: "rejected"} : i)}
          })
        )
        queryFulfilled.catch(e => {
          patchResult.undo()
        })
      }
    }),
    createOpenRequestInterest: builder.mutation<{message: string}, {slug: string}>({
      query: body => ({
        url: `open_date_requests/${body.slug}/open_date_request_interests`,
        method: 'POST'
      }),
      onQueryStarted: async ({slug}, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          gusiberiApi.util.updateQueryData("fetchFriendsOpenDateRequest", {}, request => {
            const currentUser = selectCurrentUser(store.getState())
            request.payload = request.payload.map(r => {
              if (r.slug !== slug) return r
              if (r.open_date_request_interests?.some(i => i.user.username === currentUser?.username)) {
                return {...r, open_date_request_interests: r.open_date_request_interests?.filter(i => i.user.username !== currentUser?.username)}
              }
              return {...r, open_date_request_interests: [...(r.open_date_request_interests || []), {user: currentUser, status: "pending", id: generateRandomNumber()}]}
            })
          })
        )
        queryFulfilled.catch(e => {
          patchResult.undo()
        })
      }
    }),
    closeOpenDateRequest: builder.mutation<{payload: DateRequest}, {slug: string}>({
      query: body => ({ url: `open_date_requests/${body.slug}/close_request`, method: 'POST' })
    }),
    fetchOpenDateRequest: builder.query<{payload: OpenDateRequest}, {slug: string}>({
      query: body => `open_date_requests/${body.slug}`
    }),
    fetchFriendsOpenDateRequest: builder.query<{payload: OpenDateRequest[]}, {}>({
      query: () => 'open_date_requests/timeline',
      providesTags: ['fetch_friends_open_date_request']
    }),
    markOpenRequestAsSeen: builder.mutation<{}, {slug: string}>({
      query: body => ({ url: `open_date_requests/${body.slug}/viewed`, method: 'POST' })
    }),
    updateLastOnline: builder.mutation<{}, {}>({
      query: _ => ({ url: `users/update_activity?activity_type=last_online`, method: 'POST' })
    }),
    setDemoViewed: builder.mutation<{}, {}>({
      query: _ => ({ url: `users/update_activity?activity_type=demo_viewed`, method: 'POST' })
    }),
    createBuckBreaker: builder.mutation<{payload: TBuckBreaker}, {title: string, description: string}>({
      query: body => ({ url: 'buckbreakers', method: 'POST', body })
    }),
    fetchBuckBreaker: builder.query<{payload: TBuckBreaker}, {id: string}>({
      query: body => `buckbreakers/${body.id}`
    }),
    updateBuckBreaker: builder.mutation<{payload: TBuckBreaker}, {id: string, title: string, description: string}>({
      query: body => ({ url: `buckbreakers/${body.id}`, method: 'PUT', body }),
      onQueryStarted: async ({id, ...patch}, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((result) => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchBuckBreaker", {id}, buckBreakers => {
              buckBreakers.payload = result.data.payload
            })
          )
        })
      }
    }),
    addBBParticipant: builder.mutation<{payload: TBuckBreaker}, {id: string, name: string, contact: string}>({
      query: body => ({ url: `buckbreakers/${body.id}/participants`, method: 'POST', body }),
      onQueryStarted: async ({id, ...patch}, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((result) => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchBuckBreaker", {id}, buckBreakers => {
              buckBreakers.payload = result.data.payload
            })
          )
        })
      }
    }),
    removeBBParticipant: builder.mutation<{payload: TBuckBreaker}, {id: string, pid: number}>({
      query: body => ({ url: `buckbreakers/${body.id}/participants/${body.pid}`, method: 'DELETE' }),
      onQueryStarted: async ({id, ...patch}, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((result) => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchBuckBreaker", {id}, buckBreakers => {
              buckBreakers.payload = result.data.payload
            })
          )
        })
      }
    }),
    updateBBParticipant: builder.mutation<{payload: TBuckBreaker}, {id: string, pid: number, name: string, contact: string}>({
      query: body => ({ url: `buckbreakers/${body.id}/participants/${body.pid}`, method: 'PUT', body })
    }),
    addBBExpense: builder.mutation<{payload: TBuckBreaker}, TBuckBreakerExpense>({
      query: body => ({ url: `buckbreakers/${body.id}/expenses`, method: 'POST', body }),
      onQueryStarted: async ({id, ...patch}, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((result) => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchBuckBreaker", {id}, buckBreakers => {
              buckBreakers.payload = result.data.payload
            })
          )
        })
      }
    }),
    updateBBExpense: builder.mutation<{payload: TBuckBreaker}, TBuckBreakerExpense & {expense_id: string}>({
      query: body => ({ url: `buckbreakers/${body.id}/expenses/${body.expense_id}`, method: 'PUT', body }),
      onQueryStarted: async ({id, ...patch}, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((result) => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchBuckBreaker", {id}, buckBreakers => {
              buckBreakers.payload = result.data.payload
            })
          )
        })
      }
    }),
    deleteBBExpense: builder.mutation<{payload: TBuckBreaker}, {id: string, expense_id: string}>({
      query: body => ({ url: `buckbreakers/${body.id}/expenses/${body.expense_id}`, method: 'DELETE' }),
      onQueryStarted: async ({id, ...patch}, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((result) => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchBuckBreaker", {id}, buckBreakers => {
              buckBreakers.payload = result.data.payload
            })
          )
        })
      }
    }),
    markBBExpenseAsPaid: builder.mutation<{payload: TBuckBreaker}, {id: string, participant_id: string, proof?: string}>({
      query: body => ({ url: `buckbreakers/${body.id}/mark_as_paid`, method: 'POST', body }),
      onQueryStarted: async ({id, ...patch}, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((result) => {
          dispatch(
            gusiberiApi.util.updateQueryData("fetchBuckBreaker", {id}, buckBreakers => {
              buckBreakers.payload = result.data.payload
            })
          )
        })
      }
    })
  }),
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useLazyCurrentUserQuery,
  useCurrentUserQuery,
  useDateScheduleQuery,
  usePreferredDatingActivityQuery,
  useLazyDateScheduleQuery,
  useLazyPreferredDatingActivityQuery,
  useFetchUpcomingDatesQuery,
  useLazyFetchUpcomingDatesQuery,
  useDeactivateDayMutation,
  useActivateDayMutation,
  useAddScheduleMutation,
  useDeleteScheduleMutation,
  useSetUserProfileInfoMutation,
  useChangePasswordMutation,
  useDeleteAccountMutation,
  useSendSupportMessageMutation,
  useFetchSupportIssuesQuery,
  usePostSupportConversationMutation,
  useFetchSupportConversationQuery,
  useFetchFavouriteActivitiesQuery,
  usePostFavouriteActivitiesMutation,
  useDeleteFavouriteActivityMutation,
  useUpdateFavouriteActivitiesMutation,
  useLazySearchUsersQuery,
  useProfileQuery,
  useAvailabilityQuery,
  useSendDateRequestMutation,
  useUpdateSentDateRequestMutation,
  useAcceptDateRequestMutation,
  useDeclineDateRequestMutation,
  useLazyFetchDateRequestQuery,
  useFetchActivePendingRequestQuery,
  useFetchSentRequestsQuery,
  useFetchReceivedRequestsQuery,
  useRescheduleDateRequestMutation,
  useFetchPastDatesQuery,
  useAddReviewRequestMutation,
  useSendFriendRequestMutation,
  useCreateFriendGroupRequestMutation,
  useFetchFriendGroupsRequestQuery,
  useFetchPendingFriendRequestsQuery,
  useFetchDeclinedFriendRequestsQuery,
  useAcceptFriendRequestMutation,
  useGetFriendsRequestQuery,
  useRemoveFriendRequestMutation,
  useDeclineFriendRequestMutation,
  useRenameFriendGroupRequestMutation,
  useAssignFriendsToGroupRequestMutation,
  useDeleteFriendGroupRequestMutation,
  useFetchNotificationsQuery,
  useMarkNotificationAsSeenMutation,
  useSendChatMessageMutation,
  useLazyFetchChatMessagesQuery,
  useFetchChatMessagesQuery,
  useFetchDateRequestQuery,
  useMarkMessagesAsSeenMutation,
  useResendActivationMutation,
  useActivateUserMutation,
  useResetPasswordRequestMutation,
  useResetPasswordMutation,
  useGoogleAuthMutation,
  useAddParticipantMutation,
  useRemoveParticipantMutation,
  useFetchDateScoreboardRequestQuery,
  useSaveDateScoreboardRequestMutation,
  useFetchDateCommentsQuery,
  useSaveDateCommentsMutation,
  useDeleteMemoryRequestMutation,
  useFetchDateMediaQuery,
  useSaveDateMediaMutation,
  useSaveDateExpenseMutation,
  useUpdateDateExpenseMutation,
  useDeleteDateExpenseMutation,
  useFetchDateExpenseQuery,
  useFetchPastPendingRequestQuery,
  useResendParticipantInviteMutation,
  useScheduleAnotherMutation,
  useSetNotificationSettingsMutation,
  useDeleteDateRequestMutation,
  useSendParticipantRequestMutation,
  useDeleteParticipantRequestMutation,
  useUpdateParticipantRequestMutation,
  useLazyFetchDateScoreboardRequestQuery,
  useFetchOpenDateRequestsQuery,
  useDeleteOpenDateRequestMutation,
  useUpdateOpenDateRequestMutation,
  useCreateOpenDateRequestMutation,
  useAcceptOpenRequestInterestMutation,
  useRejectOpenRequestInterestMutation,
  useCreateOpenRequestInterestMutation,
  useCloseOpenDateRequestMutation,
  useLazyFetchOpenDateRequestQuery,
  useFetchFriendsOpenDateRequestQuery,
  useMarkOpenRequestAsSeenMutation,
  useMarkAllNotificationAsSeenMutation,
  useSetDemoViewedMutation,
  useUpdateLastOnlineMutation,
  useAddSuppliesSuggestionMutation,
  useRemoveSuppliesSuggestionMutation,
  usePickSuppliesSuggestionMutation,
  useMarkExpenseAsPaidMutation,
  useFetchBuckBreakerQuery,
  useCreateBuckBreakerMutation,
  useUpdateBuckBreakerMutation,
  useAddBBParticipantMutation,
  useRemoveBBParticipantMutation,
  useUpdateBBParticipantMutation,
  useDeleteBBExpenseMutation,
  useAddBBExpenseMutation,
  useUpdateBBExpenseMutation,
  useMarkBBExpenseAsPaidMutation
} = gusiberiApi

export default gusiberiApi
