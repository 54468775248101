import React from 'react'
import { TBuckBreaker, User } from '../../utils/types'
import { generateRandomNumber, retrieveErrorMessage, uploadToS3 } from '../../utils/helpers';
import { Participant, useAddBBExpenseMutation, useAddBBParticipantMutation, useRemoveBBParticipantMutation } from '../../services/api';
import { useUiStore } from '../../stores/uiStore';
import { AWS_BASE_URL, RECEIPTS_FOLDER } from '../../utils/constants';
import { useSearchParams } from 'react-router-dom';

type TFormPayload = {name: string, contact: string}
export type TFormPayloadExpense = {
  description: string, 
  amount: '' | number, 
  debtors: Array<string|number>, 
  paymentInfo: string, 
  receipt: string
  created_by: string
}
const defaultFormData: TFormPayloadExpense = {description: '', amount: '', debtors: [], paymentInfo: '', receipt: '', created_by: ''}

const useBuckBreakerBreakdown = (data: TBuckBreaker) => {
  const [addParticipant, {isLoading: addingParticipant}] = useAddBBParticipantMutation()
  const [participantsBeingDeleted, setParticipantBeingDeleted] = React.useState<string[]>([])
  const [removeParticipant,] = useRemoveBBParticipantMutation()
  const {openErrorToast, openSuccessToast, showConfirmDialog} = useUiStore()
  const [openAddParticipant, setOpenAddParticipant] = React.useState(false)
  const [openAddExpense, setOpenAddExpense] = React.useState(false)
  const [addExpense, {isLoading: addingExpense}] = useAddBBExpenseMutation()
  const toggleAddParticipant = () => setOpenAddParticipant(!openAddParticipant)
  const participantsAvatarData = data.buck_breaker_participants.map(p => ({full_name: p.name, avatar: p.avatar} as User))
  const [expenseFormData, setExpenseFormData] = React.useState<TFormPayloadExpense>(defaultFormData)
  const [formError,] = React.useState({participants: '', amount: '', description: ''})
  const [uploadingReceipt, setUploadingReceipt] = React.useState(false)
  const receiptName = `${RECEIPTS_FOLDER}/${generateRandomNumber(10)}.jpg`
  const receiptUrl = `${AWS_BASE_URL}/${receiptName}`
  const [params,] = useSearchParams()
  const me = params.get('me')

  const onAddParticipant = async (values: TFormPayload) => {
    try {
      await addParticipant({id: data.friendly_id, ...values}).unwrap()
      openSuccessToast({message: 'Participant added successfully'})
      toggleAddParticipant()
    } catch (error) {
      openErrorToast({message: retrieveErrorMessage(error)})
    }
  }

  const onRemoveParticipant = async (pid: string) => {
    showConfirmDialog({
      dialogTitle: 'Remove Participant',
      dialogBody: 'Are you sure you want to remove this participant?',
      onConfirmAction: async () => {
        setParticipantBeingDeleted(prev => [...prev, pid])
        try {
          await removeParticipant({id: data.friendly_id, pid: +pid}).unwrap()
          openSuccessToast({message: 'Participant removed successfully'})
        } catch (error) {
          openErrorToast({message: retrieveErrorMessage(error)})
        }
        setParticipantBeingDeleted(prev => prev.filter(id => id !== pid))
      }
    })
  }

  const toggleAddExpense = () => setOpenAddExpense(!openAddExpense)

  const onChangeExpenseForm = (key: keyof TFormPayloadExpense) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | String) => {
    if (typeof e === 'string') {
      setExpenseFormData(d => ({...d, [key]: e}))
      return
    } else {
      setExpenseFormData(prev => ({...prev, [key]: (e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>).target.value}))
    }
  }

  const clearDebtors = () => {
    setExpenseFormData(d => ({...d, debtors: []}))
  }

  const selectAllAsDebtors = () => {
    setExpenseFormData(d => ({...d, debtors: data.buck_breaker_participants?.map(p => p.id) || []}))
  }

  const addAsDebtor = (id: string | number) => {
    setExpenseFormData(d => ({...d, debtors: [...d.debtors, id]}))
  }

  const removeDebtor = (id: string | number) => {
    setExpenseFormData(d => ({...d, debtors: d.debtors.filter(pid => pid !== id)}))
  }

  const toggleDebtor = (id: string | number) => {
    if (expenseFormData.debtors.includes(id)) {
      removeDebtor(id)
    } else {
      addAsDebtor(id)
    }
  }

  const allSelected = data?.buck_breaker_participants?.length === expenseFormData?.debtors?.length
  const canSave = 
    !!expenseFormData.amount && 
      !Number.isNaN(+expenseFormData.amount) &&
        !!expenseFormData.description && 
          !!expenseFormData.debtors.length
  const getCreatedById = () => 
    data.buck_breaker_participants.find(p => p.contact === me)?.id ||
    data.buck_breaker_participants.find(p => p.contact === data.user.email)?.id || ''
  const onSave = async () => {
    try {
      await addExpense({
        id: data.friendly_id, 
        ...expenseFormData,
        amount: (+expenseFormData.amount * 100),
        debtors: expenseFormData.debtors.map(d => `${d}`),
      }).unwrap()
      openSuccessToast({message: 'Expense added successfully'})
      toggleAddExpense()
    } catch (error) {
      openErrorToast({message: retrieveErrorMessage(error)})
    }
  }
  const participants: Participant[] = data.buck_breaker_participants.map(p => ({
    id: +p.id,
    name: p.name,
    avatar: p.avatar,
    contact: p.contact
  }))

  const uploadReceipt = async (image: string) => {
    setUploadingReceipt(true)
    try {
      await uploadToS3({ fileUrl: image, filename: receiptName})
    } finally {
      setUploadingReceipt(false)
      setExpenseFormData(d => ({...d, receipt: receiptUrl}))
    }
  }

  return {
    openAddParticipant, 
    toggleAddParticipant, 
    addParticipant,
    addingParticipant,
    participantsAvatarData,
    onAddParticipant,
    onRemoveParticipant,
    participantsBeingDeleted,
    addingExpense,
    addExpense,
    openAddExpense,
    toggleAddExpense,
    onChangeExpenseForm,
    expenseFormData,
    toggleDebtor,
    clearDebtors,
    selectAllAsDebtors,
    allSelected,
    canSave,
    formError,
    onSave,
    participants,
    uploadReceipt,
    uploadingReceipt,
    createdBy: getCreatedById(),
    receipt: expenseFormData.receipt
  }
}

export default useBuckBreakerBreakdown
