import React from 'react'
import Box from '@mui/material/Box'
import { TBuckBreaker } from '../../utils/types'
import Button from '../../components/Button/Button'
import ExpenseIcon from '@mui/icons-material/MonetizationOnRounded';
import TextWithIcon from '../../components/TextWithIcon'
import { colors } from '../../styles/_var'
import UserIcon from '@mui/icons-material/Face6Rounded';
import ParticipantAvatars from './ParticipantAvatars';
import useBuckBreakerBreakdown from './useBuckBreaker';
import BBExpensesList from './BBExpensesList';
import BBAddParticipantDrawer from './BBAddParticipanDrawer';
import BBAddExpenseDrawer from './BBAddExpenseDrawer';
import { BBCostBreakdown } from './BBCostBreakdown';

type BuckBreakerBreakdownProps = {
  data: TBuckBreaker
} & React.HTMLAttributes<HTMLDivElement>

const BuckBreakerBreakdown = ({data, ...rest}: BuckBreakerBreakdownProps) => {
  const { 
    openAddParticipant,
    toggleAddParticipant,
    addingParticipant,
    onAddParticipant,
    onRemoveParticipant,
    participantsBeingDeleted,
    addExpense,
    addingExpense,
    openAddExpense,
    toggleAddExpense,
    ...others
  } = useBuckBreakerBreakdown(data)
  return (
    <Box p={2} {...rest}>
      <Box display="flex" flexDirection="row">
        <Button variant="contained" center onClick={toggleAddParticipant}>
          <TextWithIcon 
            useDiv 
            icon={<UserIcon />}
            weight="semibold" 
            color={colors.white}
          >
            Add Participants
          </TextWithIcon>
        </Button>
        <Button variant="outlined" center onClick={toggleAddExpense}>
          <TextWithIcon 
            useDiv 
            icon={<ExpenseIcon />}
            weight="semibold" 
          >
            Add Expense
          </TextWithIcon>
        </Button>
      </Box>
      <Box mt={2}>
        <ParticipantAvatars 
          data={data.buck_breaker_participants} 
          onRemove={onRemoveParticipant} 
          participantsBeingDeleted={participantsBeingDeleted}
        />
      </Box>
      <BBExpensesList 
        data={data.data} 
        mt={2} 
        allParticipants={data.buck_breaker_participants} 
      />
      <BBCostBreakdown bbData={data} />
      <BBAddParticipantDrawer
        isOpen={openAddParticipant} 
        closeDrawer={toggleAddParticipant} 
        onSubmit={onAddParticipant}
        isLoading={addingParticipant}
      />
      <BBAddExpenseDrawer 
        isOpen={openAddExpense} 
        closeDrawer={toggleAddExpense} 
        onSubmit={addExpense}
        isLoading={addingExpense}
        canSave={others.canSave}
        allSelected={others.allSelected}
        clearDebtors={others.clearDebtors}
        selectAllDebtors={others.selectAllAsDebtors}
        toggleDebtors={others.toggleDebtor}
        formData={others.expenseFormData}
        formError={others.formError}
        onChange={others.onChangeExpenseForm}
        onSave={others.onSave}
        participants={others.participants}
        uploadReceipt={others.uploadReceipt}
        uploadingReceipt={others.uploadingReceipt} 
      />
    </Box>
  )
}

export type TFormPayload = {name: string, contact: string}

export default BuckBreakerBreakdown